import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, Input } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { productNoImage } from "../../assets/images"
import DatePicker from "react-datepicker"
import moment from "moment-timezone"

class BoxArrangementModal extends Component {
  state = {
    boxArrangements: []
  }

  setArrangementData = (id, key, value) => {
    const boxArrangements = this.state.boxArrangements
    const itemIdx = boxArrangements.findIndex(a => a.id === id)
    if(itemIdx > -1) {
      if(key === 'length' || key === 'width' || key === 'height') {
        boxArrangements[itemIdx]['dimension'][key] = value
      } else {
        boxArrangements[itemIdx][key] = value
      }
    }
    this.setState({ boxArrangements })
  }

  addArrangement = () => {
    let boxArrangements = this.state.boxArrangements
    boxArrangements.push({
      id: crypto.randomUUID(),
      units_per_box: 0,
      number_of_box: 0,
      dimension: {length: 0, width: 0, height: 0 },
      weight: 0,
      expiration_date: this.props.item_data.expiration_date,
      item_data: this.props.item_data
    })
    this.setState({ boxArrangements })
  }

  updateAllArrangementExpDate = (date) => {
    let boxArrangements = this.state.boxArrangements
    boxArrangements.forEach(item => item.expiration_date = date)
    this.setState({ boxArrangements })
  }

  removeArrangement = (id) => {
    let boxArrangements = this.state.boxArrangements
    boxArrangements = boxArrangements.filter(item => item.id !== id)
    this.setState({ boxArrangements })
  }

  countTotalArranged = () => {
    let total = 0
    this.state.boxArrangements.forEach(item => {
      total = total + (item.units_per_box * item.number_of_box)
    })
    return total
  }

  countTotalBoxes = () => {
    let total = 0
    this.state.boxArrangements.forEach(item => {
      total = total + item.number_of_box
    })
    return total
  }

  translateArrangementsIntoBoxes = () => {
    let boxes = []
    let boxNumber = 0
    // translate array of arrangements into per box data
    this.state.boxArrangements.forEach(boxArrangement => {
      for (let i = 0; i < boxArrangement.number_of_box; i++) {
        boxNumber++
        boxes.push({
          id: boxArrangement.id+boxNumber,
          boxNumber: boxNumber,
          units_per_box: boxArrangement.units_per_box,
          dimension: {
            length: boxArrangement.dimension.length, 
            width: boxArrangement.dimension.width, 
            height: boxArrangement.dimension.height
          },
          weight: boxArrangement.weight,
          expiration_date: boxArrangement.expiration_date
        })
      }
    })
    return boxes
  }

  getArrangedQty = (item) => {
    const { boxes } = this.props

    let item_arranged_qty = 0
    boxes.forEach(box => {
      if(box.isMultiSKU) {
        const itemIdx = box.items.findIndex(it => it.id === item.id)
        if(itemIdx > -1) {
          item_arranged_qty = item_arranged_qty + box.items[itemIdx].qty_at_multiskubox
        }
      } else {
        if(box?.item_data.id === item.id) {
          item_arranged_qty = item_arranged_qty + box.units_per_box
        }
      }
    })
    return item_arranged_qty
  }

  render() {
    const { open, onClose, item_data, onExpirationDateChange, onArrangementDone } = this.props
    const totalArranged = this.countTotalArranged()
    const totalBoxes = this.countTotalBoxes()
    const qty_already_on_boxes = this.getArrangedQty(item_data)

    return (
      <Modal open={open} onClose={onClose} size="2xl" isStatic={true}>
        <div className="flex gap-4">
          <div className="basis-1/3 text-center border rounded">
            <div
              className={`p-2 mx-auto min-h-[500px] relative bg-no-repeat bg-center`}
              style={{ backgroundImage: `url('${productNoImage}')`}}
            >
              <img
                // we need large image dimension here, recommended 500px in width
                // but fetching image by ASIN has no guarantee it will return the image, so ideally it's needed on db
                src={`https://images.amazon.com/images/P/${item_data.asin1}.01._SCLZZZZZZZ_.jpg`}
                alt=""
                className="relative z-20"
              />
            </div>
          </div>
          <div className="basis-2/3">
            <h6 className="font-semibold mb-6">{item_data.item_name}</h6>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <span className="text-slate-500">SKU</span>
                <span className="block text-lg font-semibold">{item_data.seller_sku}</span>
              </div>
              <div>
                <span className="text-slate-500">FNSKU</span>
                <img src="https://i.ibb.co.com/C6y77g6/Screen-Shot-2024-08-11-at-16-04-29.png" alt="" />
              </div>
            </div>
            <div className="mt-2">
              <span className="text-slate-500 block mb-1">Shipment Qty</span>
              <span className="block text-lg font-semibold">{item_data.qty}</span>
            </div>
            <div className="mt-2">
              <span className="text-slate-500 block mb-1">Already on Box(es)</span>
              <span className="block text-lg font-semibold">{qty_already_on_boxes}</span>
            </div>
            <div className="mt-4">
              <span className="text-slate-500 block mb-1">Expiration Date <small>(only if applicable)</small></span>
              <DatePicker
                selected={item_data.expiration_date ? item_data.expiration_date : null}
                onChange={selected => {
                  this.updateAllArrangementExpDate(selected)
                  onExpirationDateChange(selected)
                }}
              />
            </div>
            <div className="mt-6 bg-gray-50 p-2 rounded border">
              <span className="block mb-1 font-medium mb-2">Box Arrangements</span>
              <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
                <thead>
                  <tr className="border-b-2 border-slate-200">
                    <th className="px-3">NUMBERS OF BOX</th>
                    <th className="px-3">UNITS/BOX</th>
                    <th className="px-3">TOTAL QTY</th>
                    <th className="px-3">DIMENSION L&times;W&times;H <small className="lowercase">(in)</small></th>
                    <th className="px-3">WEIGHT <small className="lowercase">(lbs)</small></th>
                    <th className="px-3">EXP. DATE</th>
                    <th className="px-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.boxArrangements.map(arrangement => (
                  <tr key={`arrangement-${arrangement.id}`} className="border-t border-slate-200">
                    <td className="p-3">
                      <Input
                        type="number"
                        className="input-sm w-20"
                        min={1}
                        value={arrangement.number_of_box}
                        onChange={e => this.setArrangementData(arrangement.id, 'number_of_box', Number(e.target.value))}
                      />
                    </td>
                    <td className="p-3">
                      <Input
                        type="number"
                        className="input-sm w-20"
                        min={1}
                        value={arrangement.units_per_box}
                        onChange={e => this.setArrangementData(arrangement.id, 'units_per_box', Number(e.target.value))}
                      />
                    </td>
                    <td className="p-3">
                      {arrangement.units_per_box * arrangement.number_of_box}
                    </td>
                    <td className="p-3">
                      <div className="flex gap-1">
                        <Input
                          type="number"
                          min={0}
                          className="input-sm w-16"
                          placeholder="L"
                          value={arrangement.dimension.length}
                          onChange={e => this.setArrangementData(arrangement.id, 'length', Number(e.target.value))}
                        />
                        <Input
                          type="number"
                          min={0}
                          className="input-sm w-16"
                          placeholder="W"
                          value={arrangement.dimension.width}
                          onChange={e => this.setArrangementData(arrangement.id, 'width', Number(e.target.value))}
                        />
                        <Input
                          type="number"
                          min={0}
                          className="input-sm w-16"
                          placeholder="H"
                          value={arrangement.dimension.height}
                          onChange={e => this.setArrangementData(arrangement.id, 'height', Number(e.target.value))}
                        />
                      </div>
                    </td>
                    <td className="p-3">
                      <Input
                        type="number"
                        min={0}
                        className="input-sm w-16"
                        value={arrangement.weight}
                        onChange={e => this.setArrangementData(arrangement.id, 'weight', Number(e.target.value))}
                      />
                    </td>
                    <td className="p-3">
                      {item_data.expiration_date ? moment(item_data.expiration_date).format('MM/DD/YYYY') : 'N/A'}
                    </td>
                    <td className="p-3">
                      <span
                        role="button"
                        className="text-lg text-red-700"
                        onClick={() => this.removeArrangement(arrangement.id)}
                      >&times;</span>
                    </td>
                  </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="px-3">
                      Total Arranged:&nbsp;
                      <span
                        className={
                          totalArranged > item_data.qty ? 'text-red-700' : 
                          totalArranged === item_data.qty ? 'text-green-700' : ''
                        }>
                          {totalArranged}
                        </span>
                      {totalArranged > item_data.qty && <span className="text-red-700 ml-1">&#9888;</span>}
                      {totalArranged === item_data.qty && <span className="text-green-700 ml-1">&#10003;</span>}
                    </td>
                    <td className="px-3">
                      Remaining: <span>{totalArranged <= (item_data.qty - qty_already_on_boxes) ? 
                      Math.abs(totalArranged - (item_data.qty - qty_already_on_boxes)) : '-'}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <Button
                className="btn-secondary btn-sm mt-4"
                onClick={this.addArrangement}
              >Add Arrangement</Button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 mt-4">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className="btn-primary"
            disabled={totalArranged <= 0}
            onClick={() => {
              // open labels pdf in a new tab
              // then return arrangements data per box
              onArrangementDone(this.state.boxArrangements)
              onClose()
            }}
          >
            <IconPrint className="size-4 inline mr-2" />
            <span>Print Box Labels ({totalBoxes})</span>
          </Button>
        </div>
      </Modal>
    )
  }
}

export default BoxArrangementModal