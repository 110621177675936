import React, { Component} from "react";
import { connect } from "react-redux";
import { Button, DialogTitle, Field, Label } from "@headlessui/react";
import Modal from "../../components/Modal";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import shipmentActions from "../../redux/shipment/actions";
import moment from "moment-timezone";

const {
  shipmentActiveGetDeliveryWindow,
  shipmentActiveConfirmTransportationOptions,
} = shipmentActions;

class CompleteShipment extends Component {
  state = {
    ready_to_ship_window: null,
    transportation_option: null,
    delivery_window_option: null,
  }

  getDeliveryWindow = () => {
    const { shipment_active } = this.props;
    const { ready_to_ship_window } = this.state;
    this.props.shipmentActiveGetDeliveryWindow({
      action: "get-transportation-options",
      shipment_id: shipment_active.id,
      data: {
        ready_to_ship_window: `${moment(ready_to_ship_window).format("YYYY-MM-DDTHH:MM")}Z`,
      }
    })
  }

  generateTransportationOptions = () => {
    const { shipment_active } = this.props;
    if(!shipment_active?.transportation_options){
      return [];
    }
    return shipment_active.transportation_options.map(item => {
      return {
        label: `${item?.carrier?.name}(${item?.carrier?.alphaCode})`,
        value: item?.transportationOptionId,
        data: item,
      }
    })
  }

  confirmTransportationOptions = () => {
    const { shipment_active } = this.props;
    const {
      transportation_option,
      delivery_window_option,
    } = this.state;
    this.props.shipmentActiveConfirmTransportationOptions(
      {
        action: "confirm-transportation-options",
        shipment_id: shipment_active.id,
        data: {
          transportation_option_id: transportation_option.value,
          delivery_window_id: delivery_window_option.value,
        },
      },
      {
        success: [
          {
            function: this.props.onClose,
            params: [],
          }
        ]
      }
    )
  }

  generateDeliveryWindowOptions = () => {
    const { shipment_active } = this.props;
    if(!shipment_active?.delivery_window_options){
      return [];
    }
    return shipment_active.delivery_window_options.map(item => {
      return {
        label: `[${item?.availabilityType}] ${moment(item?.startDate).format("LLL")}-${moment(item?.endDate).format("LLL")} - valid to: ${moment(item?.validUntil).format("LLL")}`,
        value: item?.deliveryWindowOptionId,
        data: item,
      }
    })
  }

  render() {
    const {
      open,
      onClose,
      shipment_active_working,
    } = this.props
    const {
      ready_to_ship_window,
      transportation_option,
      delivery_window_option,
    } = this.state;

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="font-semibold text-lg mb-4">Complete Shipment</DialogTitle>
        <Field className="field">
          <Label>Delivery Window</Label>
          <div className="flex items-start gap-4">
            <div>
              <DatePicker
                className="w-60"
                selected={ready_to_ship_window}
                popperPlacement="bottom-start"
                onChange={selected => {
                  this.setState({ ready_to_ship_window: selected })
                }}
              />
              <small className="block text-slate-500">Choose start date of delivery window</small>
            </div>
            <Button
              onClick={() => this.getDeliveryWindow()}
              disabled={!ready_to_ship_window || shipment_active_working}
              className="btn-primary"
            >Get delivery options</Button>
          </div>
        </Field>
        <Field className="field mt-4">
          <Label>Select Shipping Carrier</Label>
          <Select
            options={this.generateTransportationOptions()}
            onChange={(e) => this.setState({transportation_option: e})}
            selected={transportation_option}
            disabled={shipment_active_working}
          />
        </Field>
        {transportation_option?.data?.preconditions?.includes("CONFIRMED_DELIVERY_WINDOW") &&
          <Field className="field mt-4">
            <Label>Confirm Delivery Window</Label>
            <Select
              options={this.generateDeliveryWindowOptions()}
              onChange={(e) => this.setState({delivery_window_option: e})}
              selected={delivery_window_option}
              disabled={shipment_active_working}
            />
          </Field>
        }
        <div className="mt-4 flex justify-end gap-4">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={() => this.confirmTransportationOptions()}
            disabled={shipment_active_working}
          >Confirm</Button>
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipment_active_working: state.Shipment.get("shipment_active_working"),
    shipment_active: state.Shipment.get("shipment_active"),
  }),
  {
    shipmentActiveGetDeliveryWindow,
    shipmentActiveConfirmTransportationOptions,
  }
)(CompleteShipment);
