import Auth from "./auth/reducer";
import User from "./user/reducer";
import Inventory from "./inventory/reducer";
import Shipment from "./shipment/reducer";

const Reducers = {
  Auth,
  User,
  Inventory,
  Shipment,
};

export default Reducers;
