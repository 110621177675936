import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle } from "@headlessui/react"
import TrackingNumberTableInput from "../ActiveShipments/TrackingNumberTableInput"

class AddTrackingNumbersModal extends Component {
  render() {
    const { open, onClose, packages } = this.props

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="text-lg font-semibold mb-5">Add tracking numbers</DialogTitle>
        <TrackingNumberTableInput
          items={packages}
        />
        <div className="flex justify-end gap-3 mt-5">
          <Button className="btn-tertiary" onClick={onClose}>Close</Button>
          <Button className="btn-primary">Save</Button>
        </div>
      </Modal>
    )
  }
}

export default AddTrackingNumbersModal