import { Field, Label, Radio, RadioGroup } from "@headlessui/react"
import React, { Component } from 'react'

export const shipment_types = [
  {value: 'SPD', label: 'SPD (Small Parcel Delivery)', label_short: 'SPD'},
  {value: 'LTL', label: 'LTL (Less Than Truckload)', label_short: 'LTL'},
]

class RadioShipmentTypes extends Component {
  render() {
    const {
      value,
      onChange,
      inlineStyle,
      additional_data,
    } = this.props

    return (
      <RadioGroup
        className={`flex ${inlineStyle ? 'flex-row gap-4' : 'flex-col gap-2'}`}
        value={value}
        by="value"
        onChange={selected => onChange(selected, additional_data)}
      >
        {shipment_types.map(item => (
        <Field key={`shipment-${item.value}`} className="flex items-center gap-2">
          <Radio className="radio" value={item} />
          <Label>{inlineStyle ? item.label_short : item.label}</Label>
        </Field>
        ))}
      </RadioGroup>
    )
  }
}

export default RadioShipmentTypes
