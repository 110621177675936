import React, { Component } from 'react'
import Modal from "./Modal"
import { Button, DialogTitle, Field, Input, Label, Checkbox } from "@headlessui/react"
import SelectCountry from "./SelectCountry"
import SelectState from "./SelectState"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CountriesData from "./CountriesData";
import StatesData from "./StatesData";

class AddressFormModal extends Component {
  state = {
    new_address_data: null,
    address_country_data: null,
  }

  componentDidMount = () => {
  }

  findCountry = (id) => {
    const country = CountriesData.find(item => item.iso2 === id);
    if(!country) { return null }
    return { value: country, label: country.name }
  }

  findState = (id_country, id_state) => {
    var country = this.findCountry(id_country);
    if(!country){ return null; }
    country = StatesData.find(item => item.id === country?.value?.id);
    if(!country){ return null; }
    const state = country?.states?.find(item => item.name === id_state);
    if(!state) { return null }
    return { value: state, label: state.name }
  }

  setAddressData = (key, value) => {
    const { new_address_data } = this.state
    if(new_address_data) {
      var new_address = {...new_address_data}
      new_address[key] = value

      if(key === "country_code"){
        new_address["country_code"] = value.iso2;
        new_address["state_or_province_code"] = null;
      }
      if(key === "state_or_province_code"){
        new_address["state_or_province_code"] = value?.name;
      }
      this.setState({ new_address_data: new_address })
    } else {
      new_address = {[key]: value}
      if(key === "country_code"){
        new_address["country_code"] = value.iso2;
      }
      if(key === "state_or_province_code"){
        new_address["state_or_province_code"] = value?.name;
      }
      this.setState({ new_address_data: new_address })
    }
  }

  setStateLocal = () => {
    this.props.onClose();
  }

  save = () => {
    const { new_address_data } = this.state;
    this.props.userAddressAdd(
      new_address_data,
      {
        success: [
          {
            function: this.setStateLocal,
            params: [],
          }
        ],
      }
    )
  }

  update = () => {
    const { new_address_data } = this.state;
    var address_data = {...new_address_data}
    address_data["id"] = this.props.editData?.id;
    this.props.userAddressUpdate(
      address_data,
      {
        success: [
          {
            function: this.setStateLocal,
            params: [],
          }
        ],
      }
    )
  }

  isFormValid = () => {
    const { new_address_data } = this.state;
    if(this.props.edit){
      return new_address_data ? true : false;
    }
    if(!new_address_data?.name
        || !new_address_data?.company_name
        || !new_address_data?.address_line_1
        || !new_address_data?.city
        || !new_address_data?.country_code
        || !new_address_data?.state_or_province_code
        || !new_address_data?.postal_code
        || !new_address_data?.phone_number
    ){ return false };
    return true;
  }

  render() {
    const { open, onClose, mode, user_address_working, edit, data } = this.props
    const { new_address_data } = this.state;

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="font-semibold text-lg mb-2">
          {mode === 'add-address' ? 'Create a new address' : 'Change Address' }
        </DialogTitle>
        <Field className="field">
          <Checkbox
            className="checkbox"
            checked={new_address_data?.default
              ? new_address_data?.default ? true : false
              : data?.default ? true : false
            }
            onChange={() => this.setAddressData("default", !new_address_data?.default)}
          />
          <Label>Default address</Label>
        </Field>
        <Field className="field">
          <Label>Address Name</Label>
          <Input
            type="text"
            placeholder="Example: Home Address, My Office, Warehouse"
            onChange={(e) => this.setAddressData("name", e.target.value)}
            value={new_address_data?.name
              ? new_address_data?.name
              : data?.name
            }
          />
        </Field>
        <Field className="field mt-4">
          <Label>Company Name</Label>
          <Input
            type="text"
            onChange={(e) => this.setAddressData("company_name", e.target.value)}
            value={new_address_data?.company_name
              ? new_address_data?.company_name
              : data?.company_name
            }
          />
        </Field>
        <Field className="field mt-4">
          <Label>Address Line 1</Label>
          <Input
            type="text"
            onChange={(e) => this.setAddressData("address_line_1", e.target.value)}
            value={new_address_data?.address_line_1
              ? new_address_data?.address_line_1
              : data?.address_line_1
            }
          />
        </Field>
        <Field className="field mt-4">
          <Label>Address Line 2</Label>
          <Input
            type="text"
            onChange={(e) => this.setAddressData("address_line_2", e.target.value)}
            value={new_address_data?.address_line_2
              ? new_address_data?.address_line_2
              : data?.address_line_2
            }
          />
        </Field>
        <div className="flex gap-4 mt-4">
          <Field className="field flex-1">
            <Label>Country</Label>
            <SelectCountry
              onChange={selected => {
                this.setAddressData("country_code", selected?.value);
                this.setState({address_country_data: selected});
              }}
              value={new_address_data?.country_code
                ? this.state.address_country_data
                : this.findCountry(data?.country_code)
              }
            />
          </Field>
          <Field className="field flex-1">
            <Label>State/Province</Label>
            <SelectState
              countryId={this.state.address_country_data
                ? this.state.address_country_data?.value?.id
                : this.findCountry(data?.country_code)?.value?.id
              }
              disabled={!this.state.address_country_data && mode === 'add-address'}
              onChange={selected => {
                this.setAddressData("state_or_province_code", selected?.value);
                this.setState({state_or_province_code_data: selected});
              }}
              value={new_address_data?.state_or_province_code
                ? this.state.state_or_province_code_data
                : this.findState(data?.country_code, data?.state_or_province_code)
              }
            />
          </Field>
        </div>
        <div className="flex gap-4 mt-4">
          <Field className="field basis-1/2">
            <Label>City</Label>
            <Input
              type="text"
              onChange={(e) => this.setAddressData("city", e.target.value)}
              value={new_address_data?.city
                ? new_address_data?.city
                : data?.city
              }
            />
          </Field>
          <Field className="field basis-1/4">
            <Label>Zip Code</Label>
            <Input
              type="text"
              className="max-w-36"
              onChange={(e) => this.setAddressData("postal_code", e.target.value)}
              value={new_address_data?.postal_code
                ? new_address_data?.postal_code
                : data?.postal_code
              }
            />
          </Field>
          <Field className="field basis-1/4">
            <Label>Phone Number</Label>
            <PhoneInput
              international
              defaultCountry="US"
              onChange={value => this.setAddressData("phone_number", value)}
              value={new_address_data?.phone_number
                ? new_address_data?.phone_number
                : data?.phone_number
              }
            />
          </Field>
        </div>
        <div className="flex items-center justify-between mt-4">
          {edit
            ?
              <Button
                className="btn-primary"
                onClick={() => this.update()}
                disabled={user_address_working || !this.isFormValid()}
              >Update</Button>
            :
              <Button
                className="btn-primary"
                onClick={() => this.save()}
                disabled={user_address_working || !this.isFormValid()}
              >Submit</Button>
          }
          <Button
            className="btn-tertiary"
            onClick={onClose}
          >Cancel</Button>
        </div>
      </Modal>
    )
  }
}

export default AddressFormModal
