import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from  "react-router-dom";
import CreateEditShipmentDraft from "./CreateEditShipmentDraft"
import shipmentActions from "../../redux/shipment/actions";

const {
  shipmentGet
} = shipmentActions;

class ViewShipmentDraft extends Component {

  componentDidMount = () => {
    this.props.shipmentGet({
      shipment: this.props?.match?.params?.id,
      with_items: true,
    })
  }

  render() {
    const { shipment } = this.props;

    return (
      <div>
        <CreateEditShipmentDraft
          mode="edit"
          data={shipment}
          selectedProducts={null}
          shipment_id={this.props?.match?.params?.id}
        />
      </div>
    )
  }
}

export default withRouter(connect(
  state => ({
    userData: state.User.get("userData"),
    shipments_working: state.Shipment.get("shipments_working"),
    shipment: state.Shipment.get("shipment"),
  }),
  {
    shipmentGet,
  }
)(ViewShipmentDraft));
