import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogPanel,
  //Disclosure,
  //DisclosureButton,
  //DisclosurePanel
} from "@headlessui/react"
import Spinner from "../../components/Spinner"
import { flagCA, flagGB, flagUS } from "../../assets/images"
//import Caret from "../../components/Caret"
import SelectPlacementOptions from "./SelectPlacementOptions"

const flagMap = {
  'US' : flagUS,
  'CA' : flagCA,
  'GB' : flagGB
}

class ShipmentPlanner extends Component {
  state = {
    proposed_shipments_in_progress: false,
    proposed_shipments_done: false
  }

  planShipping = (action) => {
    this.props.shipmentPlanCreate({
      action: action,
      shipment_id: this.props.shipment.id
    });
  }

  calculateTopStats = () => {
    const { items } = this.props;
    return (
      <div className="flex flex-row gap-4">
        <span><strong className="text-lg">{items.length}</strong> SKUs</span>
        <span>
          <strong
            className="text-lg"
          >{items.length ? items.map(item => parseInt(item.quantity)).reduce((a,b)=>a+b) : 0}
          </strong> Units
        </span>
      </div>
    )
  }

  selectPlacementOption = (select) => {
    this.props.shipmentPlanCreate({
      action: "select_placement_option",
      shipment_id: this.props.shipment.id,
      placement_option_id: select.value,
    });
  }

  testBox = () => {
    this.props.shipmentPlanCreate({
      action: "test-box",
      shipment_id: this.props.shipment.id,
    });
  }

  render() {
    const {
      shipment_working,
      shipment: shipment_data,
    } = this.props;
    console.log("SHIPMENT DATA:", shipment_data,)

    return (
      <div>
        <h3 className="font-medium text-lg mb-2">Plan Shipping</h3>
        {!shipment_data?.inbound_plan_created_success &&
          <div className="flex justify-between items-center">
            {this.calculateTopStats()}
            <Button
              className="btn-primary"
              onClick={() => this.planShipping("create_inbound_plan")}
              disabled={shipment_working}
            >Plan Shipping</Button>
          </div>
        }
        {shipment_data?.inbound_plan_created_success &&
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-4">
            </div>
            <Button
              className="btn-primary"
              onClick={() => this.planShipping("generate_placement_options")}
              disabled={shipment_working}
            >Generate Placement Options</Button>
          </div>
        }
        {shipment_data?.placement_options_data &&
          <SelectPlacementOptions
            classNames="mt-4"
            placement_options_data={shipment_data?.placement_options_data}
            onChange={(select) => this.selectPlacementOption(select)}
            select_placement_option={shipment_data?.placement_option_selected}
            disabled={shipment_working}
          />
        }
        {shipment_data?.inbound_plan_created_failed &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Shipment plan creating issues present</h3>
        </>
        }
        {(shipment_data?.inbound_plan_created_failed && shipment_data?.inbound_plan_created_data) &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Shipment plan creating issues</h3>
          <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr className="border-b-2 border-slate-200">
                <th>ERROR</th>
                <th>MESSAGE</th>
                <th>DETAILS</th>
                <th>SEVERITY</th>
              </tr>
            </thead>
            <tbody>
              {shipment_data?.inbound_plan_created_data?.operationProblems?.map((item, idx) => {
                return (
                  <tr key={`problem-${idx}`} className="border-t py-3 border-slate-200">
                    <td className="py-2">{item?.code}</td>
                    <td className="py-2">{item.message}</td>
                    <td className="py-2">{item.details}</td>
                    <td className="py-2">{item.severity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
        }
        {shipment_data?.shipments_data?.shipments &&
        <>
          <hr className="mt-6 mb-4" />
          <h3 className="font-medium text-lg mb-2">Proposed Shipments by Amazon</h3>
          <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr className="border-b-2 border-slate-200">
                <th>DESTINATION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shipment_data?.shipments_data?.shipments.map(item => (
              <tr key={`plan-${item.amazonReferenceId}`} className="border-t py-3 border-slate-200">
                <td className="py-2">
                  <div className="flex">
                    <img src={flagMap[item?.destination?.address?.countryCode]} alt="" className="w-4 mr-2" />
                    <span className="leading-none">{item?.destination?.warehouseId}<br/>{item.warehouse_location}</span>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex gap-1">
                    <Button i
                      disabled={true}
                      className="btn-secondary btn-sm"
                    >Optimize</Button>
                    <Button
                      disabled={true}
                      className="btn-primary btn-sm"
                    >Accept</Button>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="py-2 text-right" colSpan={5}>
                  <Button
                    className="btn-primary btn-sm"
                    onClick={() => this.props.shipmentPlanCreate({
                      action: "accept-all-shipments",
                      shipment_id: this.props.shipment.id,
                    })}
                  >Accept All</Button>
                </td>
              </tr>
            </tfoot>
          </table>
        </>
        }
        <Dialog open={shipment_working} onClose={() => {}}>
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-gray-950/50">
            <DialogPanel className="border bg-white rounded-md shadow-xl relative flex items-center gap-2 px-4 py-2">
              <Spinner className="size-4" /> <span className="text-slate-500">Synchronizing with Amazon...</span>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default ShipmentPlanner
