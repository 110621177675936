import { Button, DialogTitle } from "@headlessui/react"
import React, { Component } from 'react'
import Modal from "./Modal"

class ConfirmDialog extends Component {
  render() {
    const { open, onClose, type, title, text, confirmBtnText, onConfirm } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className="flex">
          <Icon type={type ? type : "info"} />
          <div className="px-4">
            <DialogTitle className="font-semibold text-lg mb-2">{title}</DialogTitle>
            <p className="text-slate-500">
              {text}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-5">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className={type === 'danger' ? 'btn-danger' : 'btn-primary'}
            onClick={onConfirm}
          >{confirmBtnText ? confirmBtnText : "OK"}</Button>
        </div>
      </Modal>
    )
  }
}

const Icon = ({ type }) => {
  if(type === 'danger') {
    return (
      <div className="rounded-full bg-red-100 mx-auto size-12 flex justify-center items-center flex-shrink-0">
        <svg className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
      </div>
    )
  } else if(type === 'warning') {
    return (
      <div className="rounded-full bg-orange-100 mx-auto size-12 flex justify-center items-center flex-shrink-0">
        <svg className="size-6 text-orange-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
      </div>
    )
  } else if(type === 'success') {
    return (
      <div className="rounded-full bg-green-100 mx-auto size-12 flex justify-center items-center flex-shrink-0">
        <svg className="size-6 fill-green-700" xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 12 12" height="12" width="12">
          <path d="M9.651 1.55a.718.718 0 0 1 1.026 0 .74.74 0 0 1 .01 1.03L4.892 9.43a.718.718 0 0 1-1.044.02L.322 5.877a.742.742 0 0 1 0-1.04.718.718 0 0 1 1.026 0L4.34 7.87l5.292-6.297Z"/>
        </svg>
      </div>
    )
  } else {
    return (
      <div className="rounded-full bg-blue-100 mx-auto size-12 flex justify-center items-center flex-shrink-0">
        <svg className="size-6 fill-blue-700" xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 12 12" height="12" width="12">
          <path d="m6.899 3.77-2.715.34-.097.451.533.099c.349.083.418.208.342.556l-.875 4.11c-.23 1.064.124 1.564.958 1.564.646 0 1.396-.299 1.736-.709l.105-.493c-.237.209-.584.292-.814.292-.325 0-.444-.23-.36-.632Zm.083-2.475a1.185 1.185 0 1 1-2.371 0 1.185 1.185 0 0 1 2.37 0"/>
        </svg>
      </div>
    )
  }
}

export default ConfirmDialog