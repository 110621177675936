import React, { Component } from 'react'
import { flagCA, flagGB, flagUS } from "../assets/images"
import { Button, Field, Input, Label, Radio, RadioGroup } from "@headlessui/react"
import { Link } from "react-router-dom"

const marketplaces = [
  {value: 'us', title: 'Amazon.com (United States)', flag: flagUS},
  {value: 'ca', title: 'Amazon.ca (Canada)', flag: flagCA},
  {value: 'gb', title: 'Amazon.co.uk (United Kingdom)', flag: flagGB},
]

class ConnectAccount extends Component {
  state = {
    selected: null,
    authorize_success: false,
    save_success: false
  }

  render() {
    return (
      <div>
        <div className="mb-5">
          <h1 className="font-semibold text-xl uppercase">Connect Account</h1>
          <p className="text-slate-500">Connect your Amazon account</p>
        </div>
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          {!this.state.authorize_success ?
          <>
            <p>Please authorize 2D BoxContent in your Amazon Seller Central, this access is necessary to work with shipment on your behalf.</p>
            <p>Select your marketplace, then click Authorize button.</p>
            <RadioGroup className="mt-4 flex flex-col gap-4" value={this.state.selected} onChange={selected => this.setState({ selected })}>
              {marketplaces.map(item => (
              <Field key={item.value} className="flex items-center gap-2">
                <Radio className="radio" value={item.value} />
                <Label className="flex items-center gap-2">
                  <img src={item.flag} className="w-7" alt="" />
                  <span>{item.title}</span>
                </Label>
              </Field>
              ))}
            </RadioGroup>
            <p className="inline-block text-slate-500 mt-8 px-3 py-1 rounded bg-blue-50 text-blue-500">You will be redirected to Amazon website, please login and confirm access for 2D BoxContent on your Amazon Seller Central.</p>
            <br/>
            <Button
              className="btn-primary mt-4"
              onClick={() => this.setState({ authorize_success: true })}
            >
              Authorize
            </Button>
          </>
          :
          <>
            {!this.state.save_success ?
            <>
              <p className="inline-block bg-green-50 text-green-700 px-3 py-1 mb-2">You've successfully authorized 2D BoxContent in your Amazon Seller Central.</p>
              <Field className="field">
                <Label>To complete your setup, please name this connection.</Label>
                <Input type="text" placeholder="Ex: My Amazon Store" className="max-w-80" />
              </Field>
              <Button
                className="btn-primary mt-4"
                onClick={() => this.setState({ save_success: true })}
              >
                Save
              </Button>
            </>
            :
            <>
              <h2 className="font-semibold mb-2 text-lg">Connection Setup Completed</h2>
              <p className="mb-4">Congratulations, you have connected your Amazon account. Start adding your products to your inventory.</p>
              <Link to="/app/my-inventory" className="btn-primary btn-link">Go to My Inventory &rarr;</Link>
            </>
            }
          </>
          }
        </div>
      </div>
    )
  }
}

export default ConnectAccount