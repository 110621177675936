import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, Input } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'

class PrintPalletLabelsModal extends Component {
  render() {
    const { open, onClose } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className="text-center w-[400px] min-h-[100px] mb-8">
          <img src="https://i.postimg.cc/br9cpw8k/pallet-label.png" alt="" className="mx-auto max-w-[300px]" />
        </div>
        <div className="flex justify-between gap-8">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <div className="flex items-center gap-2">
            <span>Copies:</span>
            <Input type="number" className="w-20" min={1} defaultValue={1} />
            <Button
              className="btn-primary"
              onClick={() => {
                // print pallet label then close
                onClose()
              }}
            ><IconPrint className="size-4 mr-1 inline" /> Print Pallet Label</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PrintPalletLabelsModal