import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* inventoryGetAll() {
  yield commonSaga(
    actions.INVENTORY_GET_ALL,
    apiCalls.get,
    apiEndpoints.inventory.all,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* inventorySearchProducts() {
  yield commonSaga(
    actions.INVENTORY_SEARCH_PRODUCTS,
    apiCalls.get,
    apiEndpoints.inventory.search.product,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* inventoryCreateProduct() {
  yield commonSaga(
    actions.INVENTORY_CREATE_PRODUCT,
    apiCalls.post,
    apiEndpoints.inventory.product,
    {
      ...default_config,
      ...{
        success_show: true
      }
    }
  );
}

export default function* inventorySaga() {
	yield all([
    fork(inventoryGetAll),
    fork(inventorySearchProducts),
    fork(inventoryCreateProduct),
	]);
}
