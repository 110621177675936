import { React, Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle } from "@headlessui/react"
import BoxArrangementsTable from "./BoxArrangementsTable"

class ViewAllBoxesModal extends Component {
  render() {
    const { open, onClose, shipment_data } = this.props
    shipment_data.boxes.sort((a, b) => {return a.boxNumber - b.boxNumber})
    return (
      <Modal open={open} onClose={onClose}>
        <DialogTitle className="font-semibold text-lg mb-5">All Shipment Boxes ({shipment_data.boxes.length})</DialogTitle>
        <BoxArrangementsTable
          boxes={shipment_data.boxes}
          viewOnly
        />
        <div className="text-center mt-4">
          <Button className="btn-tertiary" onClick={onClose}>Close</Button>
        </div>
      </Modal>
    )
  }
}

export default ViewAllBoxesModal