import { mixProductsImage, productNoImage } from "../../assets/images"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { ReactComponent as IconBox } from '../../assets/icons/icon-box.svg'
import moment from "moment-timezone"
import { Tooltip } from "react-tooltip"

export default function BoxArrangementsTable({ boxes, onDeleteBox, onViewMultiSKUBox, viewOnly, isMultiSKU }) {
  function generateRows() {
    let rowElements = []
    boxes.map(box => {
      rowElements.push(
        <tr key={`boxRow-${box.id}`}>
          <td className="p-3">
            {isMultiSKU ?
            <>
              <IconBox data-tooltip-id={`t-box-${box.id}`} className={`size-6 inline text-green-800 ${viewOnly ? 'pointer-events-none' : ''}`}
                role="button" onClick={() => onViewMultiSKUBox(box.id)}
              /> #{box.boxNumber}
            </>
            :
            <><IconBox data-tooltip-id={`t-box-${box.id}`} className="size-6 inline text-amber-800" /> #{box.boxNumber}</>
            }
            <Tooltip
              id={`t-box-${box.id}`}
              content={`${box.length}x${box.width}x${box.height} in, ${box.weight} lbs`}
            />
          </td>
          <td className="p-3">
            {isMultiSKU ?
            <img src={mixProductsImage} alt="" className="h-8" />
            :
            <img src={box?.items[0]?.image_url ? box?.items[0]?.image_url : productNoImage} alt="" className="h-8" />
            }
          </td>
          <td className="p-3">{box.quantity}</td>
          <td className="p-3">
            {box.expiration_date ? moment(box.expiration_date).format('MM/DD/YYYY') : 'N/A'}
          </td>
          {!viewOnly ?
          <td className="p-3">
            <div className="flex items-center justify-end gap-4">
              <IconPrint className="size-4 text-green-700" role="button" />
              <span
                className="text-red-700 text-lg"
                role="button"
                onClick={() => onDeleteBox(box.id)}
              >&times;</span>
            </div>
          </td>
          :
          <>
          <td className="p-3">{box.length}x{box.width}x{box.height} in</td>
          <td className="p-3">{box.weight} lbs</td>
          </>
          }
        </tr>
      )
      return null
    })
    return rowElements
  }

  return (
    <div className="overflow-y-auto max-h-[40rem]">
      <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
        <thead>
          <tr>
            <th className="px-3 sticky top-0 bg-slate-100">BOX #</th>
            <th className="px-3 sticky top-0 bg-slate-100">ITEM</th>
            <th className="px-3 sticky top-0 bg-slate-100">QTY</th>
            <th className="px-3 sticky top-0 bg-slate-100">EXP. DATE</th>
            {!viewOnly ?
            <th className="px-3 sticky top-0 bg-slate-100"></th>
            :
            <>
            <th className="px-3 sticky top-0 bg-slate-100">DIMENSION L&times;W&times;H</th>
            <th className="px-3 sticky top-0 bg-slate-100">WEIGHT</th>
            </>
            }
          </tr>
        </thead>
        <tbody>
        {generateRows()}
        </tbody>
      </table>
    </div>
  )
}
