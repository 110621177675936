import React, { Component } from 'react'
import Select from 'react-select'
import StatesData from "./StatesData";
import CountriesData from "./CountriesData";

class SelectState extends Component {
  createOptions = () => {
    var country = CountriesData.find(item => item.id === this.props.countryId);
    if(!country){ return null; }
    country = StatesData.find(item => item.id === country?.id);
    if(!country){ return null; }
    return country?.states?.map(s => { return {value: s, label: s.name} });
  }

  render() {
    const { onChange, disabled, value } = this.props

    return (
      <Select
        placeholder="Select state"
        isDisabled={disabled}
        options={this.createOptions()}
        onChange={selected => onChange(selected)}
        value={value}
      />
    )
  }
}

export default SelectState
