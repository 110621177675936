import {
  Button,
  Field,
  Input,
  Label,
  Popover,
  PopoverButton,
  PopoverPanel
} from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { ReactComponent as IconScanBarcode } from '../../assets/icons/icon-scan-barcode.svg'
import { ReactComponent as IconHistory } from '../../assets/icons/icon-history.svg'
import { Link } from "react-router-dom"
import CurrencyInput from "react-currency-input-field"
import SelectConditions from "../../components/SelectConditions"
//import StatusBadge from "../../components/StatusBadge"
import Loading from "../../components/Loading"
import inventoryActions from "../../redux/inventory/actions";
import { productNoImage } from "../../assets/images";

const {
  inventorySearchProducts,
  inventoryCreateProduct,
} = inventoryActions;

class AddProduct extends Component {
  state = {
    query: "",
    items_list_data: {},
  }

  search = () => {
    this.props.inventorySearchProducts({query: this.state.query})
  }

  updateItemsListData = (asin, key, value) => {
    const { items_list_data } = this.state;
    var data = items_list_data[asin];
    if(!data){ data = {} }
    data[key] = value;
    var list_data = {...items_list_data};
    list_data[asin] = data;
    this.setState({items_list_data: list_data});
  }

  listProduct = (asin) => {
    const data = this.state.items_list_data[asin];
    this.props.inventoryCreateProduct({
      sku: data?.sku,
      condition: data?.condition?.value,
      asin: asin,
    })
  }

  listProductFormValid = (asin) => {
    const data = this.state.items_list_data[asin];
    if(!data?.sku || !data?.condition?.value || !asin){
      return false;
    }
    return true;
  }

  render() {
    const {
      inventory_search_results,
      inventory_working,
    } = this.props;
    const { items_list_data } = this.state;
    console.log('aaaaaaaaaaaaaa', items_list_data);

    return (
      <div>
        <div className="flex items-center justify-between mb-5">
          <h1 className="font-semibold text-xl uppercase">Add Product</h1>
          <div className="flex gap-6">
            <Link to="/app/my-inventory/" className="text-slate-700">&larr; Back to My Inventory</Link>
            <Link to="/app/my-inventory/listing-history" className="flex items-center gap-1"><IconHistory className="size-4" /> Listing History</Link>
          </div>
        </div>
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          <h2 className="font-semibold text-lg mb-1">Search Products</h2>
          <p className="mb-3 text-slate-500">Find your products in Amazon's Catalog.</p>
          <div className="flex items-center gap-3">
            <IconScanBarcode className="size-8" />
            <Input
              type="text"
              placeholder="Scan item or search by name/ASIN/UPC..."
              className="w-96"
              disabled={inventory_working}
              onChange={(e) => this.setState({query: e.target.value})}
              value={this.state.query}
            />
            <Button
              className="btn-primary"
              onClick={this.search}
              disabled={inventory_working}
            >Search</Button>
          </div>
        </div>
          {inventory_working &&  <Loading text="Searching..." />}
          {inventory_search_results.length > 0 ?
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-6">
            <h2 className="font-semibold text-lg mb-1">Search Results</h2>
            <p className="text-slate-500">Select products to sell by listing them at Amazon. Please check your listing history to see the status and issue with your items.</p>
            <table className="table-auto w-full border-spacing-5 border-collapse mt-4">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">TITLE</th>
                  <th className="px-3">CATEGORY</th>
                  <th className="px-3">SALES RANK</th>
                  <th className="px-3">ASIN</th>
                  <th className="px-3"></th>
                </tr>
              </thead>
              <tbody>
                {inventory_search_results.map(item => (
                <tr key={item.asin} className="border-t py-3 border-slate-200">
                  <td className="p-3">
                    <div className="flex gap-2">
                      <img
                        src={item.images.length ? item.images[0]?.images[0]?.link : productNoImage }
                        alt={item?.summaries[0]?.itemName}
                        className="w-9"
                      />
                      <span className="line-clamp-2 max-w-md">{item?.summaries[0]?.itemName}</span>
                    </div>
                  </td>
                  <td className="p-3">{item?.classifications[0]?.classifications[0]?.displayName}</td>
                  <td className="p-3">{item?.salesRanks[0]?.classificationRanks[0]?.rank}</td>
                  <td className="p-3">{item.asin}</td>
                  <td className="p-3">
                    <Popover className="relative">
                      <PopoverButton className="btn-secondary">Select</PopoverButton>
                      <PopoverPanel anchor="top" className="flex flex-col p-3 border rounded shadow bg-white">
                        <Field className="flex items-center gap-4">
                          <Label className="min-w-24">ASIN:</Label>
                          <Input
                            type="text"
                            className="w-full"
                            disabled={true}
                            value={item.asin}
                          />
                        </Field>
                        <Field className="flex items-center gap-4 mt-4">
                          <Label className="min-w-24">Seller SKU:</Label>
                          <Input
                            type="text"
                            className="w-full"
                            onChange={(e) => this.updateItemsListData(item.asin, "sku", e.target.value)}
                            value={items_list_data[item.asin]?.sku}
                          />
                        </Field>
                        <Field className="flex items-center gap-4 mt-4">
                          <Label className="min-w-24">Price:</Label>
                          <CurrencyInput
                            prefix="$"
                            className="w-full"
                            onValueChange={value => this.updateItemsListData(item.asin, "price", value)}
                            value={items_list_data[item.asin]?.price}
                          />
                        </Field>
                        <Field className="flex items-center gap-4 mt-4">
                          <Label className="min-w-24">Conditions:</Label>
                          <SelectConditions
                            onChange={(e) => this.updateItemsListData(item.asin, "condition", e)}
                            value={items_list_data[item.asin]?.condition}
                          />
                        </Field>
                        <Button
                          className="btn-primary mt-4"
                          onClick={() => this.listProduct(item.asin)}
                          disabled={inventory_working || !this.listProductFormValid(item.asin)}
                        >List Product</Button>
                      </PopoverPanel>
                    </Popover>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          :
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-6">
            We can't find your product in the Amazon's Catalog.
          </div>
          }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    inventory_search_results: state.Inventory.get("inventory_search_results"),
    inventory_working: state.Inventory.get("inventory_working"),
  }),
  {
    inventorySearchProducts,
    inventoryCreateProduct,
  }
)(AddProduct);
