import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
  withRouter,
  Router,
  Route,
  Switch,
  Redirect
} from  "react-router-dom";
import { appConfig } from './config/app';
import authActions from './redux/auth/actions';
import userActions from './redux/user/actions';

import Login from "./views/Login";
import Spinner from "./components/Spinner";
import Dashboard from "./views/Dashboard";
import TopBar from "./components/TopBar";
import MyInventory from "./views/MyInventory";
import Account from "./views/Account";
import ConnectAccount from "./views/ConnectAccount";
import AddProduct from "./views/MyInventory/AddProduct";
import ListingHistory from "./views/MyInventory/ListingHistory";
import MainNav from "./components/MainNav";
import ShipmentDrafts from "./views/ShipmentDrafts";
import ViewShipmentDraft from "./views/ShipmentDrafts/ViewShipmentDraft";
import ActiveShipments from "./views/ActiveShipments";
import ViewActiveShipment from "./views/ActiveShipments/ViewActiveShipment";
import Settings from "./views/Settings";
import Shipped from "./views/Shipped";
import ViewShippedShipment from "./views/Shipped/ViewShippedShipment";

const { userRefreshToken } = authActions;
 const { userGetData } = userActions;

const RouteUser = ({ component: Component, isLoggedIn,...rest }) => {
  return (
    <Route
    {...rest}
    render={matchProps => (
      isLoggedIn ? (
        <TemplateUser>
          <Component {...matchProps} />
        </TemplateUser>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: matchProps.location }
          }}
        />
      )
    )}
    />
  )
}

class TemplateUser extends Component {
  render() {
    const {
      children,
      userData,
    } = this.props;

    if(userData?.username) {
      return (
        <div className="app">
          <div className="bg-white">
            <div className="2xl:container 2xl:mx-auto px-4">
              <TopBar />
            </div>
          </div>
          <div className="bg-gray-200/50 border-b border-gray-200">
            <div className="2xl:container 2xl:mx-auto px-4">
              <MainNav />
            </div>
          </div>
          <div className="2xl:container 2xl:mx-auto px-4">
            <div className="flex flex-col">
              <main className="py-6 flex-1">
                {children}
              </main>
            </div>
          </div>
        </div>
      )
    } else {
      this.props.userGetData();
      return (
        <div className="h-screen flex justify-center items-center">
          <div className="flex gap-2">
            <Spinner fillColorClass="fill-slate-500" />
            <span>Loading the app...</span>
          </div>
        </div>
      )
    }
  }
}

TemplateUser = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
       userGetData,
    }
  )(TemplateUser)
);

class AppPrivateRoutes extends Component {
  componentDidMount(){
    setInterval(() => {
      this.props.userRefreshToken();},
      appConfig.auth.refreshTokenFrequency
    );
  }

  componentDidUpdate(prevProps) {
  }

  render(){
    return (this.props.children);
  }
}

AppPrivateRoutes = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userRefreshToken,
    }
  )(AppPrivateRoutes)
);

class AppRoutes extends Component {
  render() {
    const {
      history,
      isLoggedIn
    } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/app/">
            <AppPrivateRoutes location={this.props.location}>
              <RouteUser
                exact path="/app/account"
                component={Account}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/connect"
                component={ConnectAccount}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/dashboard"
                component={Dashboard}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/my-inventory"
                component={MyInventory}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/my-inventory/add"
                component={AddProduct}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/my-inventory/listing-history"
                component={ListingHistory}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/drafts"
                component={ShipmentDrafts}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/drafts/view/:id"
                component={ViewShipmentDraft}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/active"
                component={ActiveShipments}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/active/view/:id"
                component={ViewActiveShipment}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/settings"
                component={Settings}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/shipped"
                component={Shipped}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/shipment/shipped/view/:id"
                component={ViewShippedShipment}
                isLoggedIn={isLoggedIn}
              />
            </AppPrivateRoutes>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>

    )
  }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
  }),
  {
    userRefreshToken,
  }
)(AppRoutes);
