import { Button, Checkbox } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'
import AddressFormModal from "../../components/AddressFormModal"
import ConfirmDialog from "../../components/ConfirmDialog"
import Loading from "../../components/Loading"
import userActions from "../../redux/user/actions";

const {
  userGetAddressAll,
  userAddressAdd,
  userAddressDelete,
  userAddressUpdate,
} = userActions;

class SettingAddress extends Component {
  state = {
    editData: null,
    deleteId: null,
    showAddressModal: false,
  }

  componentDidMount() {
    this.props.userGetAddressAll();
  }

  setStateLocal = (res, data) => {
    this.setState({...data})
  }

  delete = () => {
    this.props.userAddressDelete(
      {id: this.state.deleteId},
      {
        success: [
          {
            function: this.setStateLocal,
            params: [{
              deleteId: null,
            }],
          }
        ],
        failed: [
          {
            function: this.setStateLocal,
            params: [{
              deleteId: null,
            }],
          }
        ],
        error: [
          {
            function: this.setStateLocal,
            params: [{
              deleteId: null,
            }],
          }
        ]
      }
    )
  }

  render() {
    const { addresses, user_address_working } = this.props;

    return (
      user_address_working ?
        <Loading />
      :
      <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
        <div className="mb-5">
          <Button className="btn-primary btn-sm" onClick={() => this.setState({ showAddressModal: true })}>Create new address</Button>
        </div>
        {addresses.length ?
        <>
        <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3">ADDRESS NAME</th>
              <th className="px-3">COMPANY NAME</th>
              <th className="px-3">PHONE NUMBER</th>
              <th className="px-3">ADDRESS</th>
              <th className="px-3">CITY</th>
              <th className="px-3">COUNTRY</th>
              <th className="px-3">STATE/PROVINCE</th>
              <th className="px-3">ZIP CODE</th>
              <th className="px-3 text-center">DEFAULT</th>
              <th className="px-3"></th>
            </tr>
          </thead>
          <tbody>
            {addresses.map(item => (
            <tr key={`address-${item.id}`} className="border-t py-3 border-slate-200">
              <td className="p-3">
                <span
                  className="text-green-700 font-medium"
                  role="button"
                  onClick={() => this.setState({ editData: item })}
                >
                  {item.name}
                </span>
              </td>
              <td className="p-3">{item.company_name}</td>
              <td className="p-3">{item.phone_number}</td>
              <td className="p-3">{item.address_line_1}<br/>{item.address_line_2}</td>
              <td className="p-3">{item.city}</td>
              <td className="p-3">{item.country_code}</td>
              <td className="p-3">{item.state_or_province_code}</td>
              <td className="p-3">{item.postal_code}</td>
              <td className="p-3 text-center ">
                <Checkbox
                  className="checkbox"
                  checked={item.default}
                  disabled={true}
                />
              </td>
              <td className="p-3">
                <div className="flex gap-3 items-center">
                  <IconEdit className="size-4 text-slate-500" role="button" onClick={() => this.setState({ editData: item })} />
                  <IconTrash
                    className="size-4 text-red-700 data-[disabled]:opacity-25 data-[disabled]:pointer-events-none"
                    role="button"
                    data-disabled={item.is_default ? true : null}
                    onClick={() => this.setState({ deleteId: item.id })}
                  />
                </div>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        </>
        : <p className="text-slate-500">You don't have any address, please create one.</p>
        }
        <AddressFormModal
          open={this.state.editData}
          onClose={() => this.setState({ editData: null })}
          userAddressAdd={this.props.userAddressAdd}
          userAddressUpdate={this.props.userAddressUpdate}
          user_address_working={this.props.user_address_working}
          edit={this.state.editData ? true : false}
          data={this.state.editData}
          editData={this.state.editData}
        />
        <AddressFormModal
          open={this.state.showAddressModal}
          mode="add-address"
          //data={}
          onClose={() => this.setState({ showAddressModal: false })}
          userAddressAdd={this.props.userAddressAdd}
          userAddressUpdate={this.props.userAddressUpdate}
          user_address_working={this.props.user_address_working}
          edit={this.state.editData ? true : false}
          data={this.state.editData}
          editData={this.state.editData}
        />
        <ConfirmDialog
          open={this.state.deleteId}
          type="danger"
          title="Confirm Delete Address"
          text="Are you sure want to delete this address?"
          confirmBtnText="Yes"
          onClose={() => this.setState({ deleteId: null })}
          onConfirm={() => this.delete()}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    addresses: state.User.get("addresses"),
    user_address_working: state.User.get("user_address_working"),
  }),
  {
    userGetAddressAll,
    userAddressAdd,
    userAddressDelete,
    userAddressUpdate,
  }
)(SettingAddress);
