import React, { Component } from 'react'
import { connect } from "react-redux";
import Loading from "../../components/Loading"
import { Button, Checkbox, Input } from "@headlessui/react"
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'
import RadioShipmentTypes, { shipment_types } from "../../components/RadioShipmentType"
import ConfirmDialog from "../../components/ConfirmDialog"
import { Link } from "react-router-dom"
import shipmentActions from "../../redux/shipment/actions";
import moment from "moment-timezone";

const {
  shipmentGetAll,
  shipmentUpdate,
  shipmentCancelInboundPlan,
} = shipmentActions;

class ShipmentDrafts extends Component {
  state = {
    deleteId: null,
    selectedDrafts: []
  }

  componentDidMount() {
    this.props.shipmentGetAll({with_skus_count: true});
  }

  shipmentUpdateShipmentType = (data, item) => {
    this.props.shipmentUpdate({
      id: item.id,
      shipment_type: data.value,
    })
  }

  toggleSelect = (item) => {
    let selectedDrafts = this.state.selectedDrafts
    const draftIdx = selectedDrafts.findIndex(d => d.id === item.id)
    if(draftIdx > -1) {
      selectedDrafts = selectedDrafts.filter(d => d.id !== item.id)
    } else {
      selectedDrafts.push(item)
    }
    this.setState({ selectedDrafts })
  }

  toggleSelectAll = () => {
    const { shipments } = this.props;
    let selectedDrafts = this.state.selectedDrafts
    if(selectedDrafts.length < shipments?.data?.length) {
      shipments?.data?.forEach(item => {
        if(selectedDrafts.findIndex(d => d.id === item.id) === -1) {
          selectedDrafts.push(item)
        }
      })
    } else {
      selectedDrafts = []
    }
    this.setState({ selectedDrafts })
  }

  render() {
    const { shipments, shipments_working } = this.props;

    return (
      <div>
        <h1 className="font-semibold text-xl uppercase mb-5">Shipment Drafts</h1>
        {shipments_working ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          {shipments?.data?.length > 0 ?
          <>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2 mb-6">
              </div>
              <Button
                className="btn-secondary"
                disabled={this.state.selectedDrafts.length < 2}
              >Merge Shipment Drafts</Button>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th>
                  <Checkbox
                    className="checkbox"
                    checked={this.state.selectedDrafts.length === shipments?.data?.length}
                    indeterminate={this.state.selectedDrafts.length > 0 && (this.state.selectedDrafts.length < shipments?.data?.length)}
                    onChange={this.toggleSelectAll}
                  />
                  </th>
                  <th className="px-3">SHIPMENT NAME</th>
                  <th className="px-3">CREATED</th>
                  <th className="px-3">SKUs</th>
                  <th className="px-3">UNITS</th>
                  <th className="px-3 text-center">PARTNERED</th>
                  <th className="px-3">TYPE</th>
                  <th className="px-3 text-center"></th>
                </tr>
              </thead>
              <tbody>
              {shipments?.data?.map(item => (
                <tr key={`shipment-${item.id}`} className="border-t py-3 border-slate-200">
                  <td>
                    <Checkbox
                      className="checkbox"
                      checked={this.state.selectedDrafts.findIndex(d => d.id === item.id) > -1}
                      onChange={() => this.toggleSelect(item)}
                    />
                  </td>
                  <td className="p-3">
                    <Link to={`/app/shipment/drafts/view/${item.id}`}>{item.name}</Link>
                  </td>
                  <td className="p-3">{moment(item.created_at).format("LLL")}</td>
                  <td className="p-3">{item?.skus || 0}</td>
                  <td className="p-3">{item?.units || 0}</td>
                  <td className="p-3 text-center">
                    <Checkbox
                      className="checkbox"
                      checked={item.amazon_partnered ? true : false}
                      onChange={() => this.props.shipmentUpdate({
                        id: item.id,
                        amazon_partnered: !item.amazon_partnered,
                      })}
                      disabled={shipments_working}
                    />
                  </td>
                  <td className="p-3">
                    <RadioShipmentTypes
                      inlineStyle
                      value={item.shipment_type_human
                        ? shipment_types.find(st => st.value === item.shipment_type_human)
                        : null
                      }
                      onChange={this.shipmentUpdateShipmentType}
                      disabled={shipments_working}
                      additional_data={item}
                    />
                  </td>
                  <td className="p-3 text-center">
                    <div className="flex justify-end items-center gap-4">
                    <IconTrash className="size-4 text-red-700" role="button" onClick={() => this.setState({ deleteId: item.id })} />
                    <ConfirmDialog
                      open={this.state.deleteId}
                      type="danger"
                      title="Confirm Delete Shipment Draft"
                      text="Are you sure want to delete this shipment draft?"
                      confirmBtnText="Yes"
                      onClose={() => this.setState({ deleteId: null })}
                      onConfirm={() => {
                        // delete then close
                        this.setState({ deleteId: null })
                      }}
                    />
                    {item?.shipment_moved_to_active &&
                      <Button
                        className="btn-tertiary btn-sm"
                        onClick={() => this.props.shipmentCancelInboundPlan({
                          shipment: item?.id,
                        })}
                        disabled={shipments_working}
                      >
                        Cancel Inbound Plan
                      </Button>
                    }
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </>
          : <p className="text-slate-500">Currently there is no shipment drafts. Create your first draft by selecting products at <Link to="/app/my-inventory">My Inventory</Link>.</p>
          }
          </div>
        </>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipments_working: state.Shipment.get("shipments_working"),
    shipments: state.Shipment.get("shipments"),
  }),
  {
    shipmentGetAll,
    shipmentUpdate,
    shipmentCancelInboundPlan,
  }
)(ShipmentDrafts);
