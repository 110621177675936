import { Field, Input, Label } from "@headlessui/react";
import { Component } from "react";
import SelectCarrier from "../../components/SelectCarrier";

class TrackingNumberTableInput extends Component {
  state = {
    selectedCarrier: null
  }

  render() {
    const { items } = this.props

    return (
      <div>
        <Field className="field flex-row items-center gap-2 mb-4">
          <Label>Carrier:</Label>
          <SelectCarrier
            carrier={this.state.selectedCarrier}
            onChange={selected => this.setState({ selectedCarrier: selected })}
          />
          {this.state.selectedCarrier?.value === 'other' &&
          <Input type="text" placeholder="Provide carrier name" />
          }
        </Field>
        <div className="overflow-y-auto max-h-[20rem]">
          <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
            <thead>
              <tr>
                <th className="px-3 sticky top-0 bg-slate-100">#</th>
                <th className="px-3 sticky top-0 bg-slate-100">DIMENSION (L&times;W&times;H)</th>
                <th className="px-3 sticky top-0 bg-slate-100">TRACKING NUMBER</th>
              </tr>
            </thead>
            <tbody>
            {items.map((item, idx) => (
              <tr key={`boxRow-${idx}`}>
                <td className="p-3">#{item.boxNumber ? item.boxNumber : (idx+1)}</td>
                <td className="p-3">
                  {item.dimension.length} x {item.dimension.width} x {item.dimension.height} in
                </td>
                <td className="p-3">
                  <Input type="text" className="w-full" />
                </td>
            </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default TrackingNumberTableInput