import { Button, Field, Input, Label } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { flagCA, flagGB, flagUS } from "../assets/images"
import { Link } from "react-router-dom"

const connectedMarketplaces = [
  {id: 'us', name: 'My primary store'},
  {id: 'ca', name: 'My secondary store'},
]

const flagMap = {
  'us' : flagUS,
  'ca' : flagCA,
  'gb' : flagGB
}

class Account extends Component {

  render() {
    const { userData } = this.props;

    return (
      <div>
        <h1 className="font-semibold text-xl mb-5 uppercase">My Account</h1>
        <div className="flex gap-6">
          <div className="basis-1/3">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <h2 className="font-medium mb-4">Account Information</h2>
              <Field className="field">
                <Label>Username</Label>
                <Input
                  type="text"
                  readOnly
                  disabled
                  defaultValue={userData?.username}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Email</Label>
                <Input
                  type="email"
                  defaultValue={userData?.email}
                  disabled={true}
                  readOnly
                />
              </Field>
              <Button
                className="btn-primary mt-4"
              >
                Update
              </Button>
            </div>
          </div>
          <div className="basis-2/3">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <h2 className="font-medium mb-4">Connected Marketplaces</h2>
              {connectedMarketplaces.length > 0 ?
                connectedMarketplaces.map(item => (
                <div key={`m-${item.id}`} className="flex items-center gap-2 mt-4">
                  <img src={flagMap[item.id]} className="w-7" alt="" />
                  <Input type="text" defaultValue={item.name} placeholder="Ex: My Amazon Store" className="basis-80" />
                  <Button className="btn-primary">Change Name</Button>
                </div>
                ))
              :
              <div>
                <p>You don't have Amazon account connected, please connect 2D BoxContent with your Amazon account.</p>
                <Link to="/app/connect" className="btn-primary btn-link inline-block mt-4">Connect Account</Link>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
  }
)(Account);
