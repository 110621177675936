import React, { Component } from 'react'

const classes=`size-8 rounded leading-[2] text-center border text-slate-600 border-slate-300 inline-block text-sm hover:border-slate-400 data-[active]:bg-white data-[active]:border-slate-400
              data-[disabled]:opacity-50 data-[disabled]:hover:border-slate-300 data-[disabled]:pointer-events-none`
const minLen = 5;
const defaultCount = 10;

class Pagination extends Component {

  callbackFormat = (update) => {
    const { callback_params, data } = this.props;
    var cp = {};
    if(callback_params){ cp = {...callback_params}; }
    cp = {...cp, ...update}
    cp.count = data?.count ? data?.count : defaultCount;
    this.props.callback(cp);
  }

  calculatePages = () => {
    const { data } = this.props;
    const start = data?.page > minLen -2 ? data?.page - 2 : 1;
    var pagination = [];

    if(start < data?.pages - 4){
        Array(3).fill().forEach((item, page) => {
          pagination.push(
            <span
              key={`number-button-pagination-${page}-${start}`}
              role="button"
              className={classes}
              data-active={page+start === data?.page ? true : null}
              onClick={() => this.callbackFormat({page: page+start})}
            >{page+start}</span>
          )
        })
        pagination.push(<span key={"first-page-enum-4"} className="leading-loose">...</span>);
        pagination.push(
          <span
            key={`number-button-pagination-${data?.pages}`}
            role="button"
            className={classes}
            data-active={data?.page === data?.pages ? true : null}
            onClick={() => this.callbackFormat({page: data?.pages})}
          >{data?.pages}</span>
        )
    } else {
        pagination.push(
          <span
            key={`number-button-pagination-${99999999999999}`}
            role="button"
            className={classes}
            data-active={data?.page === 1 ? true : null}
            onClick={() => this.callbackFormat({page: 1})}
          >{1}</span>
        )
        pagination.push(<span key={"first-page-enum-4"} className="leading-loose">...</span>);
        Array(3).fill().forEach((item, page) => {
          pagination.push(
            <span
              key={`number-button-pagination-${page}-${start}`}
              role="button"
              className={classes}
              data-active={page+start === data?.page ? true : null}
              onClick={() => this.callbackFormat({page: page+start})}
            >{page+start}</span>
          )
        })
    }
    return pagination;
  }

  render() {
    const { data } = this.props;
    //console.log("PAGINATION:", data);
    if(!data?.pages || data?.pages <= 1){ return null };
    return (
      <div className="flex gap-1">
        {data?.page > 1 &&
          <span
            key={"first-page-enum-1"}
            role="button"
            className={classes}
            data-disabled={null}
            onClick={() => this.callbackFormat({page: 1})}
          >
            &lsaquo;&lsaquo;
          </span>
        }
            {data?.pages > minLen && data?.page > 1 &&
          <span
            key={"first-page-enum-2"}
            role="button"
            className={classes}
            data-disabled={null}
            onClick={() => this.callbackFormat({page: data?.page > 1 ? data?.page - 1 : 1})}
          >
            &lsaquo;
          </span>
        }
        {data?.pages > minLen ?
          <>
            {this.calculatePages()}
          </>
          :
          <>
            {Array(data?.pages).fill().map((item, page) => {
              return (
                <span
                  key={`number-button-pagination-${page+1}`}
                  role="button"
                  className={classes}
                  data-active={null}
                  onClick={() => this.callbackFormat({page: page+1})}
                >{page+1}</span>
              )
            })}
          </>
        }
      {(data?.page < data?.pages) &&
        <span
          key={"first-page-enum-3"}
          role="button"
          className={classes}
          data-disabled={null}
          onClick={() => this.callbackFormat({page: data?.page < data?.pages ? data?.page + 1 : data?.pages})}
        >
          &rsaquo;
        </span>
        }
       {data?.page < data?.pages &&
          <span
            key={"first-page-enum-4"}
            role="button"
            className={classes}
            data-disabled={null}
            onClick={() => this.callbackFormat({page: data?.pages})}
          >
          &rsaquo;&rsaquo;
          </span>
        }
      </div>
    )
  }
}

export default Pagination;
