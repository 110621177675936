import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
	userData: {
		username: null,
	},
  user_settings_loading: false,
  user_data_saving: false,
  data_up_to_date: false,
  addresses: [],
  user_address_working: false,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {

    case "USER_ADDRESS_UPDATE":
      return state
        .set("user_address_working", true)
    case "USER_ADDRESS_UPDATE_SUCCESS":
      var addresses_update = [...state.get("addresses")]
      if(action.data.data?.default){
        addresses_update = addresses_update.map(item => {
          if(item.id !== action.data.data.id){
            item.default = false;
          }
          return item;
        })
      }
      addresses_update = addresses_update.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      })
      return state
        .set("addresses", [...addresses_update])
        .set("user_address_working", false)
    case "USER_ADDRESS_UPDATE_FAILED":
      return state
        .set("user_address_working", false)

    case "USER_ADDRESS_DELETE":
      return state
        .set("user_address_working", true)
    case "USER_ADDRESS_DELETE_SUCCESS":
      var addresses_delete = [...state.get("addresses")]
      addresses_delete = addresses_delete.filter(item => item.id !== actions.data.data.id)
      return state
        .set("addresses", [...addresses_delete])
        .set("user_address_working", false)
    case "USER_ADDRESS_DELETE_FAILED":
      return state
        .set("user_address_working", false)

    case "USER_ADDRESS_ADD":
      return state
        .set("user_address_working", true)
    case "USER_ADDRESS_ADD_SUCCESS":
      var addresses_add = [...state.get("addresses")]
      addresses_add.unshift(action.data.data)
      if(action.data.data?.default){
        addresses_add = addresses_add.map(item => {
          if(item.id !== action.data.data.id){
            item.default = false;
          }
          return item;
        })
      }
      return state
        .set("addresses", [...addresses_add])
        .set("user_address_working", false)
    case "USER_ADDRESS_ADD_FAILED":
      return state
        .set("user_address_working", false)

    case "USER_ADDRESS_ALL":
      return state
        .set("addresses", [])
        .set("user_address_working", true)
    case "USER_ADDRESS_ALL_SUCCESS":
      return state
        .set("addresses", [...action.data.data])
        .set("user_address_working", false)
    case "USER_ADDRESS_ALL_FAILED":
      return state
        .set("addresses", [])
        .set("user_address_working", false)

    case "USER_UPDATE_SETTINGS":
      var user_settings = state.get("userData");
      user_settings.settings = {
        ...user_settings.settings,
        ...action.data
      }
      return state
        .set("userData", {...user_settings})
        .set("user_settings_loading", true)
    case "USER_UPDATE_SETTINGS_SUCCESS":
      var user_settings_success = state.get("userData");
      user_settings_success.settings = {
        ...user_settings_success.settings,
        ...action.data.data
      }
      return state
        .set("userData", {...user_settings_success})
        .set("user_settings_loading", false)
    case "USER_UPDATE_SETTINGS_FAILED":
      return state
        .set("user_settings_loading", false)

		case "USER_PASSWORD_RESET":
			return state
				.set("user_data_saving", true)
		case "USER_PASSWORD_RESET_SUCCESS":
			return state
				.set("user_data_saving", false)
		case "USER_PASSWORD_RESET_FAILED":
			return state
				.set("user_data_saving", false)

		case "USER_UPDATE_DATA":
			return state
				.set("user_data_saving", true)
    case "USER_UPDATE_DATA_SUCCESS":
			return state
				.set("userData", {...state.get("userData"), ...action.data.data})
				.set("user_data_saving", false)
		case "USER_UPDATE_DATA_ERROR":
			return state
				.set("user_data_saving", false)

		case actions.USER_GET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data.data))
				.set("data_up_to_date", true)

		case actions.USER_GET_DATA_ERROR:
			return state
				.set("data_up_to_date", false)

		default:
			return state;
  }
}
