import { Map } from "immutable";

const initState = new Map({
  inventory: [],
  inventry_working: false,
  inventory_search_results: [],
});

export default function inventoryReducer(state = initState, action) {
  switch (action.type) {

    case "INVENTORY_CREATE_PRODUCT":
      return state
        .set("inventory_working", true)
    case "INVENTORY_CREATE_PRODUCT_SUCCESS":
      return state
        .set("inventory_working", false)
    case "INVENTORY_CREATE_PRODUCT_FAILED":
      return state
        .set("inventory_working", false)

    case "INVENTORY_SEARCH_PRODUCTS":
      return state
        .set("inventory_search_results", [])
        .set("inventory_working", true)
    case "INVENTORY_SEARCH_PRODUCTS_SUCCESS":
      return state
        .set("inventory_search_results", [...action.data.data])
        .set("inventory_working", false)
    case "INVENTORY_SEARCH_PRODUCTS_FAILED":
      return state
        .set("inventory_search_results", [])
        .set("inventory_working", false)

    case "INVENTORY_GET_ALL":
      return state
        .set("inventory_working", true)
    case "INVENTORY_GET_ALL_SUCCESS":
      return state
        .set("inventory", {...action.data.data})
        .set("inventory_working", false)
    case "INVENTORY_GET_ALL_FAILED":
      return state
        .set("inventory_working", false)

		default:
			return state;
  }
}
