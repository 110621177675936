import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle } from "@headlessui/react"
import moment from "moment-timezone"

class ViewMultiSKUBoxModal extends Component {
  render() {
    const { open, onClose, box } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <DialogTitle className="font-semibold text-lg mb-5">Multi SKU Box</DialogTitle>
        <div className="flex gap-4 mb-4 border rounded px-2 py-1 bg-slate-100">
          <span>Total Qty: <strong>{box.quantity}</strong></span>
          <span>Dimension: <strong>{box.length}x{box.width}x{box.height} in</strong></span>
          <span>Weight: <strong>{box.weight} lbs</strong></span>
        </div>
        <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3">TITLE/SKU/CONDITION</th>
              <th className="px-3">QTY</th>
              <th className="px-3">EXP. DATE</th>
              <th className="px-3">FNSKU</th>
              <th className="px-3">NOTES</th>
            </tr>
          </thead>
          <tbody>
            {box?.items?.map(item => (
            <tr className="border-t py-3 border-t-slate-200" key={`product-added-${item.id}`}>
              <td className="p-3">
                <div className="flex gap-2">
                  <div className="w-14">
                    <img
                      src={item?.image_url
                        ? item?.image_url
                        : `https://www.amazon.com/images/P/${item?.item_human?.asin1}.01._THUMBZZZ_.jpg`
                      }
                      alt=""
                      className="h-12 relative top-1/2 -translate-y-1/2"
                    />
                  </div>
                  <div>
                    <span className="line-clamp-2 max-w-md text-sm leading-tight">{item?.item_human?.item_name}</span>
                    <small>SKU: {item.seller_sku} / {item?.item_human?.item_condition_human}</small>
                  </div>
                </div>
              </td>
              <td className="p-3">{item.quantity}</td>
              <td className="p-3">{item.expiration_date ? moment(item.expiration_date).format('MM/DD/YYYY') : 'N/A'}</td>
              <td className="p-3">{item.fnsku}</td>
              <td className="p-3">{item.notes}</td>
            </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 text-right">
          <Button className="btn-tertiary" onClick={onClose}>Close</Button>
        </div>
      </Modal>
    )
  }
}

export default ViewMultiSKUBoxModal
