import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, Input, Textarea } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'

class PrintCustomLabelsModal extends Component {
  render() {
    const { open, onClose } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className="text-center mt-4 mb-8">
          <Textarea rows={5} placeholder="Write text for the label" className="w-full" />
        </div>
        <div className="flex justify-between gap-8">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <div className="flex items-center gap-2">
            <span>Copies:</span>
            <Input type="number" className="w-20" min={1} defaultValue={1} />
            <Button
              className="btn-primary"
              onClick={() => {
                // print pallet label then close
                onClose()
              }}
            ><IconPrint className="size-4 mr-1 inline" /> Print Custom Label</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default PrintCustomLabelsModal