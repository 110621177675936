export default function StatusBadge({ color, text }) {
  const classMap = {
    'green'  : 'bg-green-700/10 text-green-700',
    'orange' : 'bg-orange-700/10 text-orange-500',
    'red'    : 'bg-red-700/10 text-red-500',
    'blue'   : 'bg-blue-700/10 text-blue-500',
    'neutral'  : 'bg-slate-700/10 text-slate-500',
  }

  return (
    <span className={`inline-block font-medium px-2 py-1 rounded ${classMap[color]}`}>{text}</span>
  )
}