import React, { Component } from 'react'
import { connect } from "react-redux";
import Modal from "../../components/Modal"
import { Button, Checkbox, DialogTitle, Input } from "@headlessui/react"
import { ReactComponent as IconExtLink } from '../../assets/icons/icon-external-link.svg'
import Pagination from "../../components/Pagination"
import inventoryActions from "../../redux/inventory/actions";

const { inventoryGetAll } = inventoryActions;

class AddItemsModal extends Component {
  state = {
    selected: [],
    query: "",
  }

  componentDidMount() {
    this.props.inventoryGetAll();
    this.setState({ selected: [...this.props.alreadyAddedItems] })
  }

  toggleSelect = (item) => {
    let selected = this.state.selected
    const productIdx = selected.findIndex(p => p.seller_sku === item.seller_sku)
    if(productIdx > -1) {
      selected = selected.filter(p => p.seller_sku !== item.seller_sku)
    } else {
      selected.push(item)
    }
    this.setState({ selected })
  }

  setQuantity = (item, qty) => {
    let selected = this.state.selected
    const productIdx = selected.findIndex(p => p.seller_sku === item.seller_sku)
    if(productIdx > -1) {
      selected[productIdx] = {...selected[productIdx], qty: qty}
    } else {
      selected.push({...item, qty: qty})
    }
    this.setState({ selected })
  }

  toggleSelectAll = () => {
    const { inventory } = this.props;
    let selected = this.state.selected
    if(selected.length < inventory?.data?.length) {
      inventory?.data?.forEach(item => {
        if(selected.findIndex(p => p.seller_sku === item.seller_sku) === -1) {
          selected.push(item)
        }
      })
    } else {
      selected = []
    }
    this.setState({ selected })
  }

  render() {
    const {
      open,
      onClose,
      onAddItems,
      alreadyAddedItems,
      inventory,
      inventory_working
    } = this.props;
    console.log('selected: ', this.state.selected)

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="font-semibold text-lg mb-2">Add items to shipment draft</DialogTitle>
        <div className="flex items-center justify-between mb-6 mt-4">
          <Button
            className="btn-primary"
            disabled={this.state.selected.length === 0}
            onClick={() => {
              onAddItems(this.state.selected)
              onClose()
            }}
          >Add selected items</Button>
          <div className="flex items-center gap-2">
            <Input
              type="text"
              placeholder="Title/SKU/ASIN"
              className="min-w-96"
              value={this.state.query}
              onChange={(e) => this.setState({query: e.target.value})}
            />
            <Button
              className="btn-tertiary"
              onClick={() => this.props.inventoryGetAll({query: this.state.query})}
              disabled={inventory_working}
            >Search</Button>
          </div>
        </div>
        <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th>
                <Checkbox
                  className="checkbox"
                  checked={this.state.selected.length === inventory?.data?.length}
                  indeterminate={this.state.selected.length > 0 && (this.state.selected.length < inventory?.data?.length)}
                  onChange={this.toggleSelectAll}
                />
              </th>
              <th className="px-3">TITLE</th>
              <th className="px-3">SKU</th>
              <th className="px-3">FNSKU</th>
              <th className="px-3">ASIN</th>
            </tr>
          </thead>
          <tbody>
            {inventory?.data?.map(item => (
            <tr
              key={`product-${item.seller_sku}`}
              className="border-t py-3 border-slate-200 data-[already-added]:opacity-50"
              data-already-added={alreadyAddedItems.findIndex(addedItem => addedItem.seller_sku === item.seller_sku) > -1 ? true : null}
            >
              <td>
                <Checkbox
                  className="checkbox"
                  checked={this.state.selected.findIndex(p => p.seller_sku === item.seller_sku) > -1}
                  disabled={alreadyAddedItems.findIndex(addedItem => addedItem.seller_sku === item.seller_sku) > -1}
                  onChange={() => this.toggleSelect(item)}
                />
              </td>
              <td className="px-3 py-2">
                <div className="flex gap-2">
                  <img
                    src={item.img_url
                      ? item.img_url
                      : `https://www.amazon.com/images/P/${item.asin1}.01._THUMBZZZ_.jpg`
                    }
                    alt={item.asin1}
                    className="w-9"
                  />
                  <span className="line-clamp-2 max-w-md">{item.item_name}</span>
                </div>
              </td>
              <td className="px-3 py-2">{item.seller_sku}</td>
              <td className="px-3 py-2">{item.fnsku}</td>
              <td className="px-3 py-2">
                <div className="flex items-center gap-2">
                  <a href={item.url} target="_blank" rel="noreferrer">{item.asin1}</a>
                  <IconExtLink className="size-4 text-slate-500" />
                </div>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between mt-4">
          <Button
            className="btn-primary"
            disabled={this.state.selected.length === 0}
            onClick={() => {
              onAddItems(this.state.selected)
              onClose()
            }}
          >Add selected items</Button>
          <Pagination
            data={inventory}
            callback={this.props.inventoryGetAll}
            callback_params={{query: this.state.query}}
          />
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    inventory: state.Inventory.get("inventory"),
    inventory_working: state.Inventory.get("inventory_working"),
  }),
  {
    inventoryGetAll,
  }
)(AddItemsModal);
