const inventoryActions = {
  INVENTORY_GET_ALL: 'INVENTORY_GET_ALL',
  INVENTORY_SEARCH_PRODUCTS: 'INVENTORY_SEARCH_PRODUCTS',
  INVENTORY_CREATE_PRODUCT: 'INVENTORY_CREATE_PRODUCT',

  inventoryCreateProduct: (data, additional_actions) => ({
    type: inventoryActions.INVENTORY_CREATE_PRODUCT,
    data,
    additional_actions,
  }),

  inventorySearchProducts: (data) => ({
    type: inventoryActions.INVENTORY_SEARCH_PRODUCTS,
    data
  }),

  inventoryGetAll: (data) => ({
    type: inventoryActions.INVENTORY_GET_ALL,
    data
  }),
}

export default inventoryActions;
