import React from 'react'
import { NavLink } from "react-router-dom"
import { ReactComponent as IconDashboard } from '../assets/icons/icon-dashboard.svg'
import { ReactComponent as IconInventory } from '../assets/icons/icon-inventory.svg'
import { ReactComponent as IconBoxOpen } from '../assets/icons/icon-box-open.svg'
import { ReactComponent as IconDraft } from '../assets/icons/icon-draft.svg'
import { ReactComponent as IconShipped } from '../assets/icons/icon-shipment-check.svg'
import { useLocation } from "react-router"

export default function MainNav() {
  const iconClasses="size-5"
  const menu = [
    {title: 'Dashboard', url: '/app/dashboard', icon: <IconDashboard className={iconClasses}/> },
    {title: 'My Inventory', url: '/app/my-inventory', icon: <IconInventory className={iconClasses}/> },
    {title: 'Shipment Drafts', url: '/app/shipment/drafts', icon: <IconDraft className={iconClasses}/> },
    {title: 'Active Shipments', url: '/app/shipment/active', icon: <IconBoxOpen className={iconClasses}/> },
    {title: 'Shipped', url: '/app/shipment/shipped', icon: <IconShipped className={iconClasses}/> }
  ]

  const currenLoc = useLocation()

  return (
    <div className="flex items-center gap-8">
      {menu.map(item => (
        <NavLink
          key={item.title}
          to={item.url}
          className={`flex items-center gap-2 items-center py-2 text-slate-500 leading-none
                   hover:text-green-700 data-[active]:text-green-700`}
          data-active={currenLoc.pathname.includes(item.url) ? true : null}
        >
          {item.icon}
          <span>{item.title}</span>
        </NavLink>
      ))}
    </div>
  )
}