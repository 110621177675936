import React, { Component } from 'react'
import { Link } from "react-router-dom"

class Dashboard extends Component {
  render() {
    return (
      <div>
        <h1 className="font-semibold text-xl mb-5 uppercase">Dashboard</h1>
        <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          <div className="flex items-center justify-between">
            <span>You don't have Amazon account connected, please connect 2D BoxContent with your Amazon account.</span>
            <Link to="/app/connect" className="btn-primary btn-link">Connect Account</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard