import React, { Component } from "react"
import Select from 'react-select'

const conditions = [
  { label_short: "N", value: "new_new", label: "New" },
  { label_short: "LN", value: "used_like_new", label: "Used / Like New" },
  { label_short: "VG", value: "used_very_good", label: "Used / Very Good" },
  { label_short: "G", value: "used_good", label: "Used / Good" },
  { label_short: "A", value: "used_acceptable", label: "Used / Acceptable" },
  { label_short: "R", value: "refurbished_refurbished", label: "Refurbished" },
  { label_short: "C-LN", value: "collectible_like_new", label: "Collectible / Like New" },
  { label_short: "C-VG", value: "collectible_very_good", label: "Collectible / Very Good" },
  { label_short: "C-G", value: "collectible_good", label: "Collectible / Good" },
  { label_short: "C-A", value: "collectible_acceptable", label: "Collectible / Acceptable" },
]

class SelectConditions extends Component {
  render() {
    const { menuPlacement, onChange, value } = this.props

    return (
      <Select
        options={conditions}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        value={value}
        onChange={onChange}
        menuPortalTarget={document.body}
        classNames={{
          container: () => 'min-w-60',
          menu: () => 'min-w-60',
        }}
      />
    )
  }
}

export default SelectConditions
