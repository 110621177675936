import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { connect } from "react-redux";
import { Button, DialogTitle, Input } from "@headlessui/react"
import DatePicker from "react-datepicker"
import { ReactComponent as IconScanBarcode } from '../../assets/icons/icon-scan-barcode.svg'
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
//import { productNoImage } from "../../assets/images"
import shipmentActions from "../../redux/shipment/actions";

const {
  shipmentActiveBoxCreate,
} = shipmentActions;

class MultiSKUBoxModal extends Component {
  state = {
    multiSKUBox: {
      dimension: {length: 0, width: 0, height: 0},
      weight: 0,
      items: [
      ],
    },
    search_query: ''
  }

  searchProduct = (query) => {
    let multiSKUBox = this.state.multiSKUBox
    const itemIdx = this.props.shipment?.items?.findIndex(p => (p.seller_sku === query) || (p.asin1 === query))
    if(itemIdx > -1) {
      if(!multiSKUBox.items.find(item => item.id === this.props.shipment?.items[itemIdx].id)){
        multiSKUBox.items.push({
          ...this.props.shipment?.items[itemIdx],
          units_count: 0,
          expiration_date: null
        })
        this.setState({ multiSKUBox })
      }
    } else {
      // message not found via react toastify later
      alert('Product not found at current shipment plan.')
    }
  }

  updateAddedProducts = (idx, key, value) => {
    let multiSKUBox = {...this.state.multiSKUBox}
    multiSKUBox.items[idx][key] = value
    this.setState({ multiSKUBox })
  }

  setBoxInfo = (key, value) => {
    let multiSKUBox = {...this.state.multiSKUBox}
    if(key === 'length' || key === 'width' || key === 'height') {
      multiSKUBox['dimension'][key] = value
    } else {
      multiSKUBox[key] = value
    }
    this.setState({ multiSKUBox })
  }

  removeItem = (id) => {
  }

  getArrangedQty = (item) => {
    const { shipment } = this.props

    let item_arranged_qty = 0
    shipment?.boxes?.forEach(box => {
      const itemIdx = box?.items?.findIndex(it => it.id === item.id)
      if(itemIdx > -1) {
        item_arranged_qty = item_arranged_qty + box.items[itemIdx].units_count
      }
    })
    return item_arranged_qty
  }

  validateBoxData = () => {
    const { multiSKUBox } = this.state;
    if(multiSKUBox.items.length === 0
      || multiSKUBox.items.find(item => item.units_count === 0)
      || multiSKUBox.dimension.length === 0
      || multiSKUBox.dimension.width === 0
      || multiSKUBox.dimension.height === 0
      || multiSKUBox.weight === 0
    ){ return false; }
    return true;
  }

  createBox = () => {
    const { multiSKUBox } = this.state;
    const { shipment } = this.props
    let data = {
      shipment_id: shipment.id,
      weight: multiSKUBox.weight,
      length: multiSKUBox.dimension.length,
      width: multiSKUBox.dimension.width,
      height: multiSKUBox.dimension.height,
      quantity: multiSKUBox.items.map(item => {
        return item.units_count;
      }).reduce((a, b) => a + b),
    }
    data.items = multiSKUBox.items.map(item => {
      return {
        id: item.id,
        seller_sku: item.seller_sku,
        quantity: item.units_count,
        fnsku: item.fnsku,
      }
    })
    this.props.shipmentActiveBoxCreate(
      data,
      {
        success: [
          {
            function: this.props.onClose,
            params: [],
          }
        ],
      }
    )
  }

  alreadyInBoxesQty = (item) => {
    const { shipment } = this.props
    let qty = 0;
    shipment.boxes.forEach(box => {
      box.items.forEach(box_item => {
        if(box_item.fnsku === item.fnsku){
          qty += box_item.quantity;
        }
      })
    })
    return qty;
  }

  render() {
    const {
      open,
      //onDone,
      onClose
    } = this.props
    const { multiSKUBox } = this.state

    return (
      <Modal open={open} onClose={onClose} size="2xl" isStatic={true}>
        <DialogTitle className="font-semibold text-lg mb-4">Create Multi SKU Box</DialogTitle>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-3">
            <IconScanBarcode className="size-8" />
            <Input
              type="text"
              placeholder="Scan item or search by ASIN/SKU..."
              className="w-96"
              value={this.state.search_query}
              onChange={e => this.searchProduct(e.target.value.trim())} // just to simulate scan barcode
            />
            <Button
              className="btn-tertiary"
              onClick={() => this.searchProduct(this.state.search_query.trim())}
            >Search</Button>
          </div>
          <div className="flex items-center gap-3">
            <span>L:</span>
            <Input
              type="number"
              min={0}
              className="w-20"
              placeholder="in"
              value={multiSKUBox.dimension.length}
              onChange={e => this.setBoxInfo('length', Number(e.target.value))}
            />
            <span>W:</span>
            <Input
              type="number"
              min={0}
              className="w-20"
              placeholder="in"
              value={multiSKUBox.dimension.width}
              onChange={e => this.setBoxInfo('width', Number(e.target.value))}
            />
            <span>H:</span>
            <Input
              type="number"
              min={0}
              className="w-20"
              placeholder="in"
              value={multiSKUBox.dimension.height}
              onChange={e => this.setBoxInfo('height', Number(e.target.value))}
            />
            <span>Weight:</span>
            <Input
              type="number"
              min={0}
              className="w-20"
              placeholder="lbs"
              value={multiSKUBox.weight}
              onChange={e => this.setBoxInfo('weight', Number(e.target.value))}
            />
          </div>
        </div>
        {multiSKUBox.items.length ?
        <table className="table-auto w-full border-spacing-5 border-collapse text-sm">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3">UNITS</th>
              <th className="px-3">EXPIRE</th>
              <th className="px-3">QTY</th>
              <th className="px-3">ALREADY ON BOXES</th>
              <th className="px-3">REMAINING</th>
              <th className="px-3">TITLE/SKU/CONDITION</th>
              <th className="px-3">FNSKU</th>
              <th className="px-3">NOTES</th>
              <th className="px-3"></th>
            </tr>
          </thead>
          <tbody>
            {multiSKUBox.items.map((item, idx) => (
            <tr className="border-t py-3 border-t-slate-200" key={`product-added-${item.id}`}>
              <td className="p-3">
                <Input
                  type="number"
                  className="max-w-24"
                  min={1}
                  max={item.quantity - this.alreadyInBoxesQty(item)}
                  onChange={e => this.updateAddedProducts(idx, 'units_count', parseInt(e.target.value))}
                />
              </td>
              <td className="p-3">
                <DatePicker
                  selected={item.expiration_date ? item.expiration_date : null}
                  onChange={selected => this.updateAddedProducts(idx, 'expiration_date', selected)}
                />
              </td>
              <td className="p-3">{item.current_quantity}</td>
              <td className="px-3">{this.alreadyInBoxesQty(item)}</td>
              <td className="px-3">{item.quantity - this.alreadyInBoxesQty(item)}</td>
              <td className="p-3">
                <div className="flex gap-2">
                  <div className="w-14">
                    <img
                      src={item?.image_url
                        ? item?.image_url
                        : `https://www.amazon.com/images/P/${item.asin1}.01._THUMBZZZ_.jpg`
                      }
                      alt=""
                      className="h-12 relative top-1/2 -translate-y-1/2"
                    />
                  </div>
                  <div>
                    <span className="line-clamp-2 max-w-md text-sm leading-tight">{item.item_name}</span>
                    <small>SKU: {item.seller_sku} / {item.item_condition_human}</small>
                  </div>
                </div>
              </td>
              <td className="p-3">{item.fnsku}</td>
              <td className="p-3 text-orange-600">{item.notes}</td>
              <td className="p-3">
                <span
                  className="text-red-700 text-lg"
                  role="button"
                  onClick={() => this.removeItem(item.id)}
                >&times;</span>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        : <p className="text-slate-500">Scan or search your products to be added to this multi SKU box.</p>
        }
        <div className="flex justify-end gap-4 mt-8">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={() => this.createBox()}
            disabled={!this.validateBoxData()}
          >
            <IconPrint className="size-4 inline mr-2" />
            <span>Done and Print Box Label</span>
          </Button>
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    shipment_active_working: state.Shipment.get("shipment_active_working"),
    shipment_active: state.Shipment.get("shipment_active"),
  }),
  {
    shipmentActiveBoxCreate,
  }
)(MultiSKUBoxModal);
