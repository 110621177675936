import { Button, Checkbox, Input, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import Pagination from "../../components/Pagination"
import Caret from "../../components/Caret"
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { Link } from "react-router-dom"
import Loading from "../../components/Loading"
import { ReactComponent as IconHistory } from '../../assets/icons/icon-history.svg'
import CreateEditShipmentDraft from "../ShipmentDrafts/CreateEditShipmentDraft"
import inventoryActions from "../../redux/inventory/actions";
import { ReactComponent as IconExtLink } from '../../assets/icons/icon-external-link.svg'
//import { productNoImage } from "../../assets/images";

const { inventoryGetAll } = inventoryActions;

class MyInventory extends Component {
  state = {
    loading: false,
    selectedProducts: [],
    mode: 'list',
    query: "",
  }

  componentDidMount() {
    this.props.inventoryGetAll();
  }

  toggleSelect = (item) => {
    let selectedProducts = this.state.selectedProducts
    const productIdx = selectedProducts.findIndex(p => p.id === item.id)
    if(productIdx > -1) {
      selectedProducts = selectedProducts.filter(p => p.id !== item.id)
    } else {
      selectedProducts.push(item)
    }
    this.setState({ selectedProducts })
  }

  selectAll = () => {
    let selectedProducts = this.state.selectedProducts
    const inventory = this.props
    if(selectedProducts.length < inventory?.total) {
      inventory?.data.forEach(item => {
        if(selectedProducts.findIndex(p => p.id === item.id) === -1) {
          selectedProducts.push(item)
        }
      })
    } else {
      selectedProducts = []
    }
    this.setState({ selectedProducts })
  }

  render() {
    const { inventory, inventory_working } = this.props;
    const { query } = this.state;

    if(this.state.mode === 'list') {
    return (
      <div>
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center gap-4">
            <h1 className="font-semibold text-xl uppercase">My Inventory</h1>
            <Link to="/app/my-inventory/add" className="btn-secondary btn-link">Add Product</Link>
          </div>
          <div className="flex items-center gap-4">
            <Menu>
              <MenuButton
                className="btn-primary flex items-center gap-2"
                disabled={this.state.selectedProducts?.length === 0}
              >
                <span>Create shipment draft...</span>
                <Caret />
              </MenuButton>
              <MenuItems anchor="bottom start">
                <MenuItem as="div" onClick={() => this.setState({ mode: 'create-draft' })}>Create new shipment draft</MenuItem>
                <MenuItem as="div">Add to existing shipment draft</MenuItem>
              </MenuItems>
            </Menu>
            <Link to="/app/my-inventory/listing-history" className="flex items-center gap-1"><IconHistory className="size-4" /> Listing History</Link>
          </div>
        </div>
        {inventory_working ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
            {inventory?.total ?
            <>
              <div className="flex items-center gap-2 mb-6">
                <Input
                  type="text"
                  placeholder="Title/SKU/ASIN"
                  className="min-w-96"
                  value={query}
                  onChange={(e) => this.setState({ query: e.target.value })}
                />
                <Button
                  className="btn-tertiary"
                  disabled={inventory_working || !query}
                  onClick={() => this.props.inventoryGetAll({query: query})}
                >Search</Button>
              </div>
              <table className="table-auto w-full border-spacing-5 border-collapse">
                <thead>
                  <tr className="border-b-2 border-slate-200">
                    <th>
                    <Checkbox
                      className="checkbox"
                      checked={this.state.selectedProducts?.length === inventory?.total}
                      indeterminate={this.state.selectedProducts?.length > 0 && (this.state.selectedProducts?.length < inventory?.total)}
                      onChange={this.selectAll}
                    />
                    </th>
                    <th className="px-3">TITLE</th>
                    <th className="px-3">SKU</th>
                    <th className="px-3">FNSKU</th>
                    <th className="px-3">ASIN</th>
                    <th className="px-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {inventory.data.map(item => (
                  <tr key={`inventory-row-${item.id}`} className="border-t py-3 border-slate-200">
                    <td>
                      <Checkbox
                        className="checkbox"
                        checked={this.state.selectedProducts?.findIndex(p => p.id === item.id) > -1}
                        onChange={() => this.toggleSelect(item)}
                      />
                    </td>
                    <td className="p-3">
                      <div className="flex gap-2">
                        <img
                          src={item.image_url
                            ? item.image_url
                            : `https://www.amazon.com/images/P/${item.asin1}.01._THUMBZZZ_.jpg`
                          }
                          alt=""
                          className="w-9"
                        />
                        <span className="line-clamp-2 max-w-md">{item.item_name}</span>
                      </div>
                    </td>
                    <td className="p-3">{item.seller_sku}</td>
                    <td className="p-3">{item.listing_id}</td>
                    <td className="p-3">
                      <div className="flex items-center gap-2">
                        <a href={`https://www.amazon.com/dp/${item.asin1}`} target="_blank" rel="noreferrer">{item.asin1}</a>
                        <IconExtLink className="size-4 text-slate-500" />
                      </div>
                    </td>
                    <td className="p-3"><IconPrint className="size-6 text-slate-500" role="button" /></td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </>
            : <p className="text-slate-500">Currently there is no product in your inventory.</p>
            }
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              data={inventory}
              callback={this.props.inventoryGetAll}
              callback_params={
                {
                  query: query ? query : null,
                }
              }
            />
          </div>
        </>
        }
      </div>
    )
    } else {
      return (
        <CreateEditShipmentDraft
          mode="create"
          selectedProducts={this.state.selectedProducts}
          cancel={() => this.setState({ mode: 'list' })}
        />
      )
  }}
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    inventory: state.Inventory.get("inventory"),
    inventory_working: state.Inventory.get("inventory_working"),
  }),
  {
    inventoryGetAll,
  }
)(MyInventory);
