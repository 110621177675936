import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./user/saga";
import inventorySagas from "./inventory/saga";
import shipmentSagas from "./shipment/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    inventorySagas(),
    shipmentSagas(),
  ]);
}
