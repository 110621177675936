import React, { Component } from 'react'
import Pagination from "../../components/Pagination"
import { Link } from "react-router-dom"
import Loading from "../../components/Loading"
import StatusBadge from "../../components/StatusBadge"
import { Button, Input } from "@headlessui/react"
import ConfirmDialog from "../../components/ConfirmDialog"
import Select from 'react-select'

const history_items = [
  {asin: 'B005PLQIQ4', img_url: 'https://m.media-amazon.com/images/I/51DOyE1fx2L._SL200_.jpg',
    title: 'Crest + Scope Complete Whitening Toothpaste, Minty Fresh, 5.4 Oz (Pack of 3)', sku: 'ABC-12345-00', condition: 'New', price: '$6.34', status: 'success',
    last_update: '07/22/2024 09:30 PM', issue: `WARNING: The item_name is too long. Remove redundant and duplicate information in the item_name. WARNING: Attributes tagged as 
    relevant_attributes are incomplete. Provide values for the following attribute(s): scent, active_ingredients
     `
  },
  {asin: 'B0B9RQJFDV', img_url: 'https://m.media-amazon.com/images/I/419JooYfvgL._SL200_.jpg', 
    title: `Sonic Electric Toothbrush for Adults, USB Rechargeable Sonic Toothbrush with 8 Brush Heads, Smart Timer, 
    5 Modes, 2-hour Fast Charge Last 30 Days, Pink', sku: 'ABC-12345-00`, sku: 'ABC-12345-01', condition: 'New', price: '$11.99', status: 'error',
    last_update: '07/22/2024 09:30 PM', issue: 'ERROR: This product has other listing limitations.'}
]

const statusMap = {
  'success' : 'green',
  'error'   : 'red',
  'warning' : 'orange',
  'pending' : 'neutral'
}

const status_options = [
  {value: 'success', label: 'SUCCESS'},
  {value: 'error', label: 'ERROR'},
  {value: 'warning', label: 'WARNING'},
  {value: 'pending', label: 'PENDING'}
]

class ListingHistory extends Component {
  state = {
    loading: false,
    show_confirm_clear_history: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    setTimeout(() => this.setState({ loading: false }), 1000)
  }

  render() {
    return (
      <div>
        <div className="flex items-center justify-between mb-5">
          <h1 className="font-semibold text-xl uppercase">Listing History</h1>
          <div className="flex items-center gap-6">
            <Link to="/app/my-inventory/" className="text-slate-700">&larr; Back to My Inventory</Link>
            <Button 
              className="btn-danger"
              onClick={() => this.setState({ show_confirm_clear_history: true })}
            >Clear History</Button>
            <ConfirmDialog
              open={this.state.show_confirm_clear_history}
              type="danger"
              title="Confirm Clear History"
              text="Are you sure want to delete all of your listing history items?"
              confirmBtnText="Yes"
              onClose={() => this.setState({ show_confirm_clear_history: false })}
              onConfirm={() => {
                // clear all history
              }}
            />
          </div>
        </div>
        {this.state.loading ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-8">
            {history_items.length > 0 ?
            <>
              <div className="flex items-center gap-4 mb-6">
                <Select
                  options={status_options}
                  placeholder="Filter by status"
                  isSearchable={false}
                  isClearable={true}
                  classNames={{
                    container: () => 'min-w-40',
                    menu: () => 'min-w-40',
                  }}
                />
                <div className="flex items-center gap-2">
                  <Input type="text" placeholder="Title/SKU/ASIN" className="min-w-96" />
                  <Button className="btn-tertiary">Search</Button>
                </div>
              </div>
              <table className="table-auto w-full border-spacing-5 border-collapse mt-4">
                <thead>
                  <tr className="border-b-2 border-slate-200">
                    <th className="px-3">TITLE</th>
                    <th className="px-3">SKU</th>
                    <th className="px-3">ASIN</th>
                    <th className="px-3">CONDITION</th>
                    <th className="px-3">PRICE</th>
                    <th className="px-3">STATUS</th>
                    <th className="px-3">LAST UPDATE</th>
                    <th className="px-3">ISSUE</th>
                    <th className="px-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {history_items.map(item => (
                  <tr key={item.asin} className="border-t py-3 border-slate-200">
                    <td className="p-3">
                      <div className="flex gap-2">
                        <img src={item.img_url} alt={item.title} className="w-9" />
                        <span className="line-clamp-2 max-w-md">{item.title}</span>
                      </div>
                    </td>
                    <td className="p-3 text-nowrap">{item.sku}</td>
                    <td className="p-3 text-nowrap">{item.asin}</td>
                    <td className="p-3">{item.condition}</td>
                    <td className="p-3">{item.price}</td>
                    <td className="p-3"><StatusBadge color={statusMap[item.status]} text={item.status.toUpperCase()} /></td>
                    <td className="p-3 text-nowrap">{item.last_update}</td>
                    <td className="p-3 w-96">{item.issue}</td>
                    <td className="p-3">
                      <span
                        className="text-lg text-red-500"
                        role="button"
                        onClick={() => console.log('delete history item')}
                      >&times;</span>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </>
            : <p className="text-slate-500">No listing history found. Once you have added product for listing, it will be shown here.</p>
            }
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              data={{page: 1, pages: 5}}
            />
          </div>
        </>
        }
      </div>
    )
  }
}

export default ListingHistory