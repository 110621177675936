import { Dialog, DialogPanel } from "@headlessui/react";
import Spinner from "./Spinner";

export default function LoadingDialog({ open, text }) {
  return (
    <Dialog open={open} onClose={() => {}}>
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-gray-950/50">
        <DialogPanel className="border bg-white rounded-md shadow-xl relative flex items-center gap-2 px-4 py-2">
          <Spinner className="size-4" /> <span className="text-slate-500">{text}</span>
        </DialogPanel>
      </div>
    </Dialog>
  )
}