import { Field, Label, Radio, RadioGroup } from "@headlessui/react"
import React, { Component } from 'react'

export const packing_types = [
  {value: 'INDIVIDUAL', label: 'Individual Products'},
  {value: 'CASE', label: 'Case-Packed'},
]

class RadioPackingTypes extends Component {
  render() {
    const { value, onChange } = this.props

    return (
      <RadioGroup
        className="flex flex-col gap-2"
        value={value}
        by="value"
        onChange={selected => onChange(selected)}
      >
        {packing_types.map(item => (
        <Field key={`packing-${item.value}`} className="flex items-center gap-2">
          <Radio className="radio" value={item} />
          <Label>{item.label}</Label>
        </Field>
        ))}
      </RadioGroup>
    )
  }
}

export default RadioPackingTypes
