import React, { Component } from 'react'
import Select from 'react-select'

const carriers = [
  {value: 'DHL', label: 'DHL'},
  {value: 'FEDEX', label: 'FedEx'},
  {value: 'USPS', label: 'USPS'},
  {value: 'UPS', label: 'UPS'},
  {value: 'Other', label: 'Other'}
]

class SelectCarrier extends Component {
  render() {
    const { menuPlacement, carrier, onChange } = this.props
    const value = carrier ? carriers.find(item => item.value === carrier.value) : null

    return (
      <Select
        placeholder="Select Carrier"
        options={carriers}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        value={value}
        onChange={onChange}
        isSearchable={false}
        classNames={{
          container: () => 'min-w-40',
          menu: () => 'min-w-40',
        }}
      />
    )
  }
}

export default SelectCarrier