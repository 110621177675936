import { Button, Field, Input, Label } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import authActions from "../redux/auth/actions";

const {
  userLogin,
} = authActions;

class Login extends Component {
  state = {
    email: '',
    pw: '',
    show_pw: false,
  }

  login = () => {
    const { email, pw } = this.state;
    this.setState({ pw: ""})
    const data = {
      username: email,
      password: pw,
    }
    this.props.userLogin(
      data,
      {
        success: [
          {
            function: this.setStateLocal,
            params: [
              {
                email: "",
                password: "",
              }
            ]
          }
        ]
      }
    );
  }

  setStateLocal = (partialState, callback) => {
    this.setState(partialState, callback)
  }

  render() {
    return (
      <div className="container mx-auto flex flex-col h-screen items-center justify-center gap-8">
        <div className="font-bold text-lg">
            2D BoxContent
          </div>
        <div className="px-8 py-8 rounded-md shadow-lg flex flex-col gap-3 w-96 bg-white justify-center">
          <h2 className="font-semibold text-xl mb-3">Sign in to your account</h2>
          <Field className="field">
            <Label>Username</Label>
            <Input
              type="text"
              placeholder="Enter username"
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Field>
          <Field className="field mt-2">
            <Label>Password</Label>
            <div className="relative">
              <Input
                type={this.state.show_pw ? 'text' : 'password'}
                className="w-full"
                placeholder="Enter password"
                onChange={e => this.setState({ pw: e.target.value })}
              />
              <small
                className="absolute top-3 right-2 cursor-pointer text-slate-500"
                onClick={() => this.setState({ show_pw: !this.state.show_pw })}
              >{this.state.show_pw ? 'HIDE' : 'SHOW'}</small>
            </div>
          </Field>
          <Button
            className="btn-primary mt-2"
            onClick={this.login}
            disabled={this.props.login_working}
          >
            Login
          </Button>
          <div className="text-sm text-center mt-2">
            <p><span className="text-slate-500">Don't have an account?</span> <Link to="/register">Register</Link></p>
            <p className="mt-1"><Link to="/forgot-password">Forgot password</Link></p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    login_failed: state.Auth.get("login_failed"),
    login_failed_message: state.Auth.get("login_failed_message"),
    login_working: state.Auth.get("login_working"),
  }),
  {
    userLogin,
  }
)(Login);
