import React, { Component } from 'react'
import Select from 'react-select'
import CountriesData from "./CountriesData";

class SelectCountry extends Component {
  createOptions = () => {
    return CountriesData.map(c => { return {value: c, label: c.name}})
  }

  render() {
    const { onChange, disabled, value } = this.props

    return (
      <Select
        placeholder="Select country"
        isDisabled={disabled}
        options={this.createOptions()}
        onChange={selected => onChange(selected)}
        value={value}
      />
    )
  }
}

export default SelectCountry
