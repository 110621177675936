const shipmentActions = {
  SHIPMENT_CREATE: 'SHIPMENT_CREATE',
  SHIPMENT_GET_ALL: 'SHIPMENT_GET_ALL',
  SHIPMENT_UPDATE: 'SHIPMENT_UPDATE',
  SHIPMENT_GET: 'SHIPMENT_GET',
  SHIPMENT_PLAN_CREATE: 'SHIPMENT_PLAN_CREATE',
  SHIPMENT_GET_ACTIVE_ALL: 'SHIPMENT_GET_ACTIVE_ALL',
  SHIPMENT_GET_ACTIVE: 'SHIPMENT_GET_ACTIVE',
  SHIPMENT_ACTIVE_BOX_CREATE: 'SHIPMENT_ACTIVE_BOX_CREATE',
  SHIPMENT_ACTIVE_BOX_DELETE: 'SHIPMENT_ACTIVE_BOX_DELETE',
  SHIPMENT_ACTIVE_SET_PACKAGE_INFO: 'SHIPMENT_ACTIVE_SET_PACKAGE_INFO',
  SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW: 'SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW',
  SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS: 'SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS',
  SHIPMENT_CANCEL_INBOUND_PLAN: 'SHIPMENT_CANCEL_INBOUND_PLAN',
  SHIPMENT_ACTIVE_GET_LABELS: 'SHIPMENT_ACTIVE_GET_LABELS',

  shipmentActiveGetLabels: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_GET_LABELS,
    data,
    additional_actions,
  }),

  shipmentCancelInboundPlan: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_CANCEL_INBOUND_PLAN,
    data,
    additional_actions,
  }),

  shipmentActiveConfirmTransportationOptions: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_CONFIRM_TRANSPORTATION_OPTIONS,
    data,
    additional_actions,
  }),

  shipmentActiveGetDeliveryWindow: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_GET_DELIVERY_WINDOW,
    data,
    additional_actions,
  }),

  shipmentActiveSetPackageInfo: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_SET_PACKAGE_INFO,
    data,
    additional_actions,
  }),

  shipmentActiveBoxDelete: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_BOX_DELETE,
    data,
    additional_actions,
  }),

  shipmentActiveBoxCreate: (data, additional_actions) => ({
    type: shipmentActions.SHIPMENT_ACTIVE_BOX_CREATE,
    data,
    additional_actions,
  }),

  shipmentGetActive: (data) => ({
    type: shipmentActions.SHIPMENT_GET_ACTIVE,
    data
  }),

  shipmentGetActiveAll: (data) => ({
    type: shipmentActions.SHIPMENT_GET_ACTIVE_ALL,
    data
  }),

  shipmentPlanCreate: (data) => ({
    type: shipmentActions.SHIPMENT_PLAN_CREATE,
    data
  }),

  shipmentGet: (data) => ({
    type: shipmentActions.SHIPMENT_GET,
    data
  }),

  shipmentUpdate: (data) => ({
    type: shipmentActions.SHIPMENT_UPDATE,
    data
  }),

  shipmentGetAll: (data) => ({
    type: shipmentActions.SHIPMENT_GET_ALL,
    data
  }),

  shipmentCreate: (data) => ({
    type: shipmentActions.SHIPMENT_CREATE,
    data
  }),
}

export default shipmentActions;
