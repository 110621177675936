import { Button, Input } from "@headlessui/react"
import React, { Component } from 'react'
import { ReactComponent as IconCloudSync } from '../../assets/icons/icon-cloud-sync.svg'
import LoadingDialog from "../../components/LoadingDialog"
import { productNoImage } from "../../assets/images"
import BoxArrangementsTable from "../ActiveShipments/BoxArrangementsTable"

const items_example = [
  {id: 1, asin1: 'B005PLQIQ4', image_url: 'https://m.media-amazon.com/images/I/51DOyE1fx2L._SL200_.jpg' , item_name: 'Crest + Scope Complete Whitening Toothpaste, Minty Fresh, 5.4 Oz (Pack of 3)', 
    seller_sku: 'ABC-12345-00', fnsku: 'X003QPTJBXTB', condition: 'NEW', qty: 100, shipped_qty: 100, received_qty: 100, notes: 'seal the box'},
  {id: 2, asin1: 'B0B9RQJFDV', image_url: '' , item_name: `Sonic Electric Toothbrush for Adults, USB Rechargeable Sonic Toothbrush with 8 Brush Heads, Smart Timer, 
    5 Modes, 2-hour Fast Charge Last 30 Days, Pink', sku: 'ABC-12345-00`, seller_sku: 'ABC-12345-01' , fnsku: 'X003QPTJABCDE', condition: 'NEW', qty: 50, 
    shipped_qty: 50, received_qty: 15, notes: ''},
  {id: 3, asin1: 'B0BSRCQR2S1', image_url: 'https://m.media-amazon.com/images/I/61TGFSr0TeL._AC_SX679_.jpg' , item_name: 'Boka Fluoride Free Toothpaste- Nano Hydroxyapatite, Remineralizing, Sensitive Teeth, Whitening- Dentist Recommended for Adult, Kids Oral Care- Watermelon Mint Flavor, 4oz 1Pk - US Manufactured', 
    seller_sku: 'ABC-12345-10', fnsku: 'X003QPTJBXAB', condition: 'NEW', qty: 200, shipped_qty: 100, received_qty: 0, notes: 'Seal the top and use bubble wrap.'},
]

const shipment_data = {
  shipment_id: 'FBA1883VSKXJ', shipment_name: 'Healthcare Shipment', destination: 'ABE8', status: 'receiving', 
  total_qty: 350, total_received_qty: 0, shipment_type: 'SPD', amazonPartnered: true,
  items: [
    items_example[0], items_example[1], items_example[2],
  ],
  boxes: [
    {
      id: "592aeba4-1c21-48e7-a27c-af5363d569410", boxNumber: 1, units_per_box: 95, dimension: { length: 14, width: 14, height: 4 },
      weight: 40, item_data: items_example[0], isMultiSKU: false
    },
    {
      id: "592aeba4-1c21-48e7-a27c-af5363d569411", boxNumber: 2, units_per_box: 43, dimension: { length: 14, width: 14, height: 4 }, 
      weight: 30, item_data: items_example[1], isMultiSKU: false
    },
    {
      id: "d60d620c-6d64-45fc-9382-f0e708176b310", boxNumber: 3, units_per_box: 200, dimension: { length: 28, width: 28, height: 8 }, 
      weight: 60, item_data: items_example[2], isMultiSKU: false
    },
    {
      id: "56393d21-f133-48e0-97bc-985d118e47b8", boxNumber: 4, units_per_box: 12, dimension: { length: 10, width: 8, height: 5 }, 
      weight: 25, items: [{...items_example[0], qty_at_multiskubox: 5}, {...items_example[1], qty_at_multiskubox: 7}], isMultiSKU: true
    }
  ]
}

class ViewShippedShipment extends Component {
  state = {
    sync_working: false,
    activeRowIdx: 0
  }

  syncShipment = () => {
    this.setState({ sync_working: true })
    setTimeout(() => this.setState({ sync_working: false }), 2000)
  }

  render() {
    const { activeRowIdx } = this.state

    const currentItemBoxes = shipment_data.boxes.filter(box => 
      !box.isMultiSKU && (box.item_data.id === shipment_data.items[activeRowIdx].id)
    )
    const multiSKUBoxes = shipment_data.boxes.filter(box => box.isMultiSKU)

    return (
      <div>
        <div className="mb-5 flex justify-between items-center">
          <div>
            <div className="flex gap-4">
              <span className="text-slate-500">View Shipped Shipment</span>
              <span className="text-green-700" role="button" onClick={() => this.props.history.goBack()}>&larr; Back</span>
            </div>
            <h1 className="font-semibold text-xl uppercase">{shipment_data.shipment_name} (ID: {shipment_data.shipment_id})</h1>
          </div>
          <div className="flex items-center gap-8">
            <div>
              <span className="text-slate-500">Destination</span>
              <span className="block font-semibold">{shipment_data.destination}</span>
            </div>
            <div>
              <span className="text-slate-500">Shipment Type</span>
              <span className="block font-semibold">{shipment_data.shipment_type}</span>
            </div>
            <div>
              <span className="text-slate-500">Amazon Partnered</span>
              <span className="block font-semibold">{shipment_data.amazonPartnered ? 'Partnered' : 'Non-Partnered'}</span>
            </div>
            <div>
              <span className="text-slate-500">Status</span>
              <span className="block font-semibold">{shipment_data.status.toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white basis-1/2">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center gap-2">
                <Input type="text" placeholder="Title/SKU/ASIN" className="min-w-96" />
                <Button className="btn-tertiary">Search</Button>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  className="btn-tertiary"
                  disabled={this.state.sync_working}
                  onClick={this.syncShipment}
                >
                  <IconCloudSync className="mr-1 size-4 inline" /> 
                  <span>Sync</span>
                </Button>
                <LoadingDialog text="Synchronizing with Amazon..." open={this.state.sync_working} />
              </div>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">TITLE</th>
                  <th className="px-3">QTY</th>
                  <th className="px-3">SHIPPED</th>
                  <th className="px-3">RECEIVED</th>
                  <th className="px-3">DIFF</th>
                </tr>
              </thead>
              <tbody>
              {shipment_data?.items.map((item, idx) => (
                <tr
                  key={`row-${item.id}`}
                  className={`border-t py-3 border-t-slate-200 hover:bg-slate-50 cursor-pointer 
                    data-[active]:bg-slate-100 data-[fullyreceived]:bg-green-700/15 data-[active]:data-[fullyreceived]:bg-green-700/25`}
                  onClick={() => this.setState({ activeRowIdx: idx })}
                  data-active={activeRowIdx === idx ? true : null}
                  data-fullyreceived={item.received_qty === item.shipped_qty ? true : null}
                >
                  <td className="p-3">
                    <div className="flex gap-2">
                      <div className="w-14">
                        <img
                          src={item.image_url
                            ? item.image_url
                              : productNoImage
                          }
                          alt=""
                          className="h-12 relative top-1/2 -translate-y-1/2"
                        />
                      </div>
                      <div>
                        <span className="line-clamp-2 max-w-md text-sm leading-tight">{item.item_name}</span>
                        <small>SKU: {item.seller_sku} / {item.condition}</small>
                      </div>
                    </div>
                  </td>
                  <td className="p-3">{item.qty}</td>
                  <td className="p-3">{item.shipped_qty}</td>
                  <td className="p-3">{item.received_qty}</td>
                  <td className="p-3">{item.received_qty - item.shipped_qty}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="basis-1/2">
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
              <h2 className="font-medium mb-4">Multi SKU Boxes</h2>
              {multiSKUBoxes.length &&
                <BoxArrangementsTable
                  boxes={multiSKUBoxes}
                  viewOnly
                />
              }
            </div>
            <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white mt-4">
              <h2 className="font-medium mb-4">Single SKU Boxes</h2>
              <div className="flex gap-2 mb-4">
                <div className="w-8">
                  <img
                    src={shipment_data.items[activeRowIdx].image_url
                      ? shipment_data.items[activeRowIdx].image_url
                        : productNoImage
                    }
                    alt=""
                    className="h-8 relative top-1/2 -translate-y-1/2"
                  />
                </div>
                <div>
                  <span className="max-w-96 line-clamp-2 text-sm leading-tight">{shipment_data.items[activeRowIdx].item_name}</span>
                </div>
              </div>
              {currentItemBoxes?.length &&
              <BoxArrangementsTable
                boxes={currentItemBoxes}
                viewOnly
              />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewShippedShipment