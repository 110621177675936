import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { clearTokenLocal, saveTokenLocal } from "../../helpers/auth/utils";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import userActions from "../user/actions";
import { client } from "../../config/api";
import { refreshTokenInternalAPI } from "../../helpers/auth/token_refresh";
import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* userLogin() {
	yield takeLatest(actions.USER_LOGIN, function*(payload) {
	    try {
  			const { data } = payload;
  			const res = yield call(apiCalls.post, apiEndpoints.auth.login, data);
	  		if(res.data?.data?.error) {
		  		yield put(actions.userLoginError(res.data?.data));
  			  sdMessage(res.data?.data?.error);
  			} else {
          yield put(actions.userLoginSuccess(res.data?.data));
          saveTokenLocal(res.data?.data);
          yield put(push(`/app/dashboard`));
          window.location.reload();
        }
			} catch (error) {
  			console.log('userLogin - ERROR!', error);
	  		yield put(actions.userLoginError({}));
		  	sdMessage('Login error')
		  }
	})
}

export function* userLogout() {
	yield takeLatest(actions.USER_LOGOUT, function*(payload) {
		const { data } = payload;
		clearTokenLocal();
    yield put(userActions.clearUserData())
    yield put(push(data?.data || "/login"));
	})
}

export function* userRefreshToken() {
	yield takeLatest(actions.USER_REFRESH_TOKEN, function*(payload) {
	    try {
			const res = yield refreshTokenInternalAPI(client).catch(
				err => {throw err;});
			if (!res.data?.data?.access) {
				yield put(actions.userRefreshTokenError());
			} else {
				if(res.access_token){
					const data = {access: res.data?.data?.access};
					yield put(actions.userRefreshTokenSuccess(data));
					saveTokenLocal(data);
				}
			}
		} catch (error) {
      yield put(actions.userLogout("/login"));
		}
	})
}

export function* userRegister() {
  yield commonSaga(
    actions.USER_REGISTER,
    apiCalls.post,
    apiEndpoints.auth.register,
    {
      ...default_config,
      ...{
        success_show: false,
        redirect_on_success: "/login"
      }
    }
  );
}

export function* adminLogin() {
	yield takeLatest(actions.ADMIN_LOGIN, function*(payload) {
	    try {
  			const { data } = payload;
  			const res = yield call(apiCalls.post, apiEndpoints.auth.admin_login, data);
	  		if (res.data.error) {
		  		yield put(actions.adminLoginError(res.data));
			  	sdMessage(res.data.error);
  			} else {
	  			yield put(actions.adminLoginSuccess(res.data));
		  		saveTokenLocal(res.data);
			  	yield put(userActions.userSetDataSuccess(res.data.user_data));
  			  yield put(push("/rating/dashboard"));
          window.location.reload();
			}
		} catch (error) {
			console.log('adminLogin - ERROR!', error);
			yield put(actions.adminLoginError({}));
			sdMessage('Login error')
		}
	})
}

export function* userPasswordForgot() {
	yield takeLatest(actions.USER_PASSWORD_FORGOT, function*(payload) {
	    try {
  			const { data } = payload;
  			const res = yield call(apiCalls.post, apiEndpoints.auth.register_password_reset, data);
	  		if (res.data.error) {
		  		yield put(actions.userPasswordForgotError(res.data));
  			} else {
	  			yield put(actions.userPasswordForgotSuccess(res.data));
			}
		} catch (error) {
			yield put(actions.userPasswordForgotError({}));
		}
	})
}

export function* userPasswordForgotReset() {
	yield takeLatest(actions.USER_PASSWORD_FORGOT_RESET, function*(payload) {
	    try {
  			const { data } = payload;
  			const res = yield call(apiCalls.put, apiEndpoints.auth.register_password_reset, data);
	  		if (res.data.error) {
		  		yield put(actions.userPasswordForgotResetError(res.data));
  			} else {
	  			yield put(actions.userPasswordForgotResetSuccess(res.data));
          yield put(push(data?.data || "/login"));
			}
		} catch (error) {
			yield put(actions.userPasswordForgotResetError({}));
		}
	})
}

export default function* authSaga() {
	yield all([
		fork(userLogin),
		fork(userLogout),
		fork(userRefreshToken),
		fork(userRegister),
		fork(adminLogin),
		fork(userPasswordForgot),
		fork(userPasswordForgotReset),
	]);
}
