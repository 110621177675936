import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle, Field, Input, Label } from "@headlessui/react"
import Select from 'react-select'

const page_type_options = [
  {
    value: "PackageLabel_Letter_2",
    label: "Two labels per US Letter label sheet.",
    desc: "This is the only valid value for Amazon-partnered shipments in the US that use United Parcel Service (UPS) as the carrier. Supported in Canada and the US."
  },
  {
    value: "PackageLabel_Letter_4",
    label: "Four labels per US Letter label sheet.",
    desc: "This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.",
  },
  {
    value: "PackageLabel_Letter_6",
    label: "Six labels per US Letter label sheet.",
    desc: "This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.",
  },
  {
    value: "PackageLabel_Letter_6_CarrierLeft",
    label: "PackageLabel_Letter_6_CarrierLeft",
    desc: "",
  },
  {
    value: "PackageLabel_A4_2",
    label: "Two labels per A4 label sheet.",
    desc: "",
  },
  {
    value: "PackageLabel_A4_4",
    label: "Four labels per A4 label sheet.",
    desc: "",
  },
  {
    value: "PackageLabel_Plain_Paper",
    label: "One label per sheet of US Letter paper.",
    desc: "Only for non-Amazon-partnered shipments.",
  },
  {
    value: "PackageLabel_Plain_Paper_CarrierBottom",
    label: "PackageLabel_Plain_Paper_CarrierBottom",
    desc: "",
  },
  {
    value: "PackageLabel_Thermal",
    label: "For use of a thermal printer.",
    desc: "Supports Amazon-partnered shipments with UPS.",
  },
  {
    value: "PackageLabel_Thermal_Unified",
    label: "Unified, for use of a thermal printer.",
    desc: "Supports shipments with ATS.",
  },
  {
    value: "PackageLabel_Thermal_NonPCP",
    label: "NonPCP, for use of a thermal printer.",
    desc: "Supports non-Amazon-partnered shipments.",
  },
  {
    value: "PackageLabel_Thermal_No_Carrier_Rotation",
    label: "No carrier rotation, for use of a thermal printer.",
    desc: "Supports Amazon-partnered shipments with DHL.",
  },
];

const label_type_options = [
  {
    value: "BARCODE_2D",
    label: "2D Barcode",
    desc: "This option is provided only for shipments where 2D Barcodes will be applied to all packages. Amazon strongly recommends using the UNIQUE option to get package labels instead of the BARCODE_2D option.",
  },
  {
    value: "UNIQUE",
    label: "Unique",
    desc: "Document data for printing unique shipping labels and carrier labels for an inbound shipment.",
  },
  {
    value: "PALLET",
    label: "Pallet",
    desc: "Document data for printing pallet labels for a Less Than Truckload/Full Truckload (LTL/FTL) inbound shipment.",
  }
]

class GetLabelsModal extends Component {
  state = {
    page_type: null,
    label_type: null,
    packages: 0,
    labels: "",
    pallets: 0,
    page_size: 0,
    page_start_index: 0,
  }

  getLabels = () => {
    const {
      page_type,
      label_type,
      packages,
      labels,
      pallets,
      page_size,
      page_start_index,
    } = this.state;
    const { shipment_active } = this.props;
    this.props.shipmentActiveGetLabels({
      action: "get-labels",
      shipment_id: shipment_active?.id,
      data: {
        page_type: page_type?.value || null,
        label_type: label_type?.value || null,
        number_of_packages: packages || null,
        package_labels_to_print: labels || null,
        number_of_pallets: pallets || null,
        page_size: page_size || null,
        page_start_index: page_start_index || null,
      }
    })
  }

  render() {
    const {
      page_type,
      label_type,
      packages,
      labels,
      pallets,
      page_size,
      page_start_index,
    } = this.state;
    const {
      open,
      onClose,
      shipment_active_working,
    } = this.props
    return (
      <Modal open={open} onClose={onClose}>
        <DialogTitle className="text-lg font-semibold mb-5">Get Amazon Labels</DialogTitle>
        <Field className="field">
          <Label>Page Type</Label>
          <Select
            options={page_type_options}
            value={page_type}
            onChange={(e) => this.setState({page_type: e})}
            disabled={shipment_active_working}
          />
        </Field>
        <Field className="field mt-4">
          <Label>Label Type</Label>
          <Select
            options={label_type_options}
            value={label_type}
            onChange={(e) => this.setState({label_type: e})}
            disabled={shipment_active_working}
          />
        </Field>
        <Field className="field mt-4">
          <Label>Number of Packages</Label>
          <Input
            type="number"
            value={packages}
            onChange={(e) => this.setState({packages: e.target.value})}
            disabled={shipment_active_working}
          />
        </Field>
        <Field className="field mt-4">
          <Label>Packages Labels to Print</Label>
          <Input
            type="text"
            value={labels}
            onChange={(e) => this.setState({labels: e.target.value})}
            disabled={shipment_active_working}
          />
        </Field>
        <Field className="field mt-4">
          <Label>Number of Pallets</Label>
          <Input
            type="number"
            value={pallets}
            onChange={(e) => this.setState({pallets: e.target.value})}
            disabled={shipment_active_working}
          />
        </Field>
        <div className="grid grid-cols-2 gap-4">
          <Field className="field mt-4">
            <Label>Page Size</Label>
            <Input
              type="number"
              value={page_size}
              onChange={(e) => this.setState({page_size: e.target.value})}
              disabled={shipment_active_working}
            />
          </Field>
          <Field className="field mt-4">
            <Label>Page Start Index</Label>
            <Input
              type="number"
              value={page_start_index}
              onChange={(e) => this.setState({page_start_index: e.target.value})}
              disabled={shipment_active_working}
            />
          </Field>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            className="btn-primary"
            onClick={() => this.getLabels()}
            disabled={shipment_active_working}
          >Confirm</Button>
        </div>
      </Modal>
    )
  }
}

export default GetLabelsModal
