import React, { Component } from 'react'
import Modal from "../../components/Modal"
import { Button, DialogTitle } from "@headlessui/react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ReactComponent as IconCopy } from '../../assets/icons/icon-copy.svg'

class ViewTrackingNumbersModal extends Component {
  state = {
    copiedIdx: null
  }

  getAllTrackingNumbers = () => {
    let all_numbers = ''
    this.props.carriers.forEach(c => {
      all_numbers += c.tracking_number + '\n'
    })
    return all_numbers
  }

  render() {
    const { open, onClose, carriers } = this.props

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <DialogTitle className="text-lg font-semibold mb-5">Tracking Numbers</DialogTitle>
        <div className="mb-3">
          <CopyToClipboard
            text={this.getAllTrackingNumbers()}
            onCopy={() => this.setState({
              copiedAll: true,
              copiedIdx: null
            })}
          >
            <Button className="btn-primary">
              <IconCopy className="size-4 inline mr-2" />
              Copy all to clipboard
            </Button>
          </CopyToClipboard>
          {this.state.copiedAll && <em className="ml-2">Copied all!</em>}
        </div>
        <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
          <thead>
            <tr className="border-b-2 border-slate-200">
              <th className="px-3"></th>
              <th className="px-3">TRACKING NUMBER</th>
              <th className="px-3">CARRIER</th>
            </tr>
          </thead>
          <tbody>
            {carriers?.map((c, idx) => (
            <tr key={`tracking-${idx}`} className="border-t py-3 border-slate-200">
              <td className="p-3 w-4">{idx + 1}.</td>
              <td className="p-3">
                <a href={`https://replace_with_link.to.carrier/${c.tracking_number}`} rel="noopenner noreferrer">
                  {c.tracking_number}
                </a>
                <CopyToClipboard
                  text={c.tracking_number}
                  onCopy={() => this.setState({
                    copiedIdx: idx,
                    copiedAll: false
                  })}
                >
                  <IconCopy className="size-4 inline ml-2" role="button" />
                </CopyToClipboard>
                <em className={`ml-2 ${this.state.copiedIdx === idx ? 'opacity-1' : 'opacity-0'}`}>Copied!</em>
              </td>
              <td className="p-3">{c.carrier_name}</td>
            </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Button className="btn-tertiary" onClick={onClose}>Close</Button>
        </div>
      </Modal>
    )
  }
}

export default ViewTrackingNumbersModal