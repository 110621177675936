import React, { Component } from 'react'
import Loading from "../../components/Loading"
import { Button, Input } from "@headlessui/react"
import { Link } from "react-router-dom"
import Pagination from "../../components/Pagination"
import { ReactComponent as IconDownload } from "../../assets/icons/icon-download.svg"
import { ReactComponent as IconMapLocation } from "../../assets/icons/icon-map-location.svg"
import { ReactComponent as IconInput } from "../../assets/icons/icon-input.svg"
import { Tooltip } from "react-tooltip"
import ViewTrackingNumbersModal from "./ViewTrackingNumbersModal"
import AddTrackingNumbersModal from "./AddTrackingNumbersModal"
import ConfirmDialog from "../../components/ConfirmDialog"

const addresses = [
  {id: 1, name: 'Home', address_line1: 'Everett, WA 98203', address_line2: '', city: 'Everett', state: 'Washington', country: 'United States', zipcode: '98203', is_default: true },
  {id: 2, name: 'Warehouse', address_line1: '612 Virginia Ave', address_line2: '', city: 'Fairmont', state: 'Wisconsin', country: 'United States', zipcode: '26554', is_default: false },
]


const shipped = [
  {shipment_id: 'FBA1883VSKXJ', shipment_name: 'Healthcare Shipment', status: 'receiving',
    destination: {warehouse_code: 'ABE8', warehouse_location: 'Florence, NJ', warehouse_country: 'US'},
    total_qty: 350, received_qty: 115, ship_from_address: addresses[1], shipment_type: 'SPD', created: '07/22/2024 09:30 AM', updated: '2 seconds ago',
    boxes: [
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99c0", boxNumber: 1, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99c1", boxNumber: 2, dimension: { length: 14, width: 11, height: 4 } }
    ],
    tracking_data: [
      {id: 1, carrier_name: 'Amazon', tracking_number: '878364678738648' },
      {id: 2, carrier_name: 'Amazon', tracking_number: '878364678738777' }
    ]
  },
  {shipment_id: 'FBA1883VSAAA', shipment_name: 'Small Books Shipment', status: 'shipped',
    destination: {warehouse_code: 'ABE8', warehouse_location: 'Charlotte, NC', warehouse_country: 'US'},
    total_qty: 100, received_qty: 0, ship_from_address: addresses[0], shipment_type: 'SPD', created: '07/20/2024 11:53 AM', updated: '1 hour ago',
    boxes: [
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a1", boxNumber: 1, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a2", boxNumber: 2, dimension: { length: 14, width: 11, height: 4 } },
    ],
    tracking_data : []
  },
  {shipment_id: 'FBA1883VSKYS', shipment_name: 'Books Shipment', status: 'receiving',
    destination: {warehouse_code: 'CLT2', warehouse_location: 'Charlotte, NC', warehouse_country: 'US'},
    total_qty: 500, received_qty: 350, ship_from_address: addresses[1], shipment_type: 'LTL', created: '07/18/2024 11:53 AM', updated: '07/24/2024 01:30 PM',
    boxes: [
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a1", boxNumber: 1, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a2", boxNumber: 2, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a3", boxNumber: 3, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a4", boxNumber: 4, dimension: { length: 14, width: 11, height: 4 } },
    ],
    pallets: [
      {id: 1, dimension: { length: 48, width: 40, height: 22 }}
    ],
    tracking_data : []
  },
  {shipment_id: 'FBA2239GDKRT', shipment_name: 'Toys Shipment', status: 'closed',
    destination: {warehouse_code: 'ABE8', warehouse_location: 'Charlotte, NC', warehouse_country: 'US'},
    total_qty: 100, received_qty: 100, ship_from_address: addresses[0], shipment_type: 'SPD', created: '07/12/2024 09:22 AM', updated: '07/22/2024 02:20 PM',
    boxes: [
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a5", boxNumber: 3, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a6", boxNumber: 4, dimension: { length: 14, width: 11, height: 4 } },
      { id: "4d4d0421-af39-482b-8c0e-2544291bf99a7", boxNumber: 4, dimension: { length: 14, width: 11, height: 4 } },
    ],
    tracking_data : [ // package are boxes
      { carrier_name: 'UPS', tracking_number: '66273548276354823' },
      { carrier_name: 'UPS', tracking_number: '66273548276354952' },
      { carrier_name: 'UPS', tracking_number: '66273548276352845' }
    ]
  },
]

class Shipped extends Component {
  state = {
    loading: false,
    showTrackingShipmentId: null,
    showAddTrackingShipmentId: null,
    cancelId: null
  }

  componentDidMount() {
    this.setState({ loading: true })
    setTimeout(() => this.setState({ loading: false }), 800)
  }

  render() {
    return (
      <div>
        <h1 className="font-semibold text-xl uppercase mb-5">Shipped</h1>
        {this.state.loading ?
        <Loading />
        :
        <>
          <div className="px-5 py-4 border border-slate-200/50 rounded-lg shadow bg-white">
          {shipped.length > 0 ?
          <>
            <div>
              <div className="flex items-center gap-2 mb-6">
                <Input type="text" placeholder="Shipment ID/Name" className="min-w-96" />
                <Button className="btn-tertiary">Search</Button>
              </div>
            </div>
            <table className="table-auto w-full border-spacing-5 border-collapse text-[15px]">
              <thead>
                <tr className="border-b-2 border-slate-200">
                  <th className="px-3">SHIPMENT ID</th>
                  <th className="px-3">SHIPMENT NAME</th>
                  <th className="px-3">RECEIVED</th>
                  <th className="px-3">STATUS</th>
                  <th className="px-3">DESTINATION</th>
                  <th className="px-3">SHIP-FROM</th>
                  <th className="px-3">TYPE</th>
                  <th className="px-3">CREATED</th>
                  <th className="px-3">UPDATED</th>
                  <th className="px-3"></th>
                </tr>
              </thead>
              <tbody>
                {shipped.map(item => (
                <tr key={`shipment-${item.shipment_id}`} className="border-t py-3 border-slate-200">
                  <td className="p-3">
                    <Link to={`/app/shipment/shipped/view/${item.shipment_id}`}>{item.shipment_id}</Link>
                  </td>
                  <td className="p-3">{item.shipment_name}</td>
                  <td className="p-3">
                    <div className="px-1 h-2 relative bg-slate-700 text-xs text-white rounded overflow-clip text-center">
                      <div
                        className="bg-green-700 absolute left-0"
                        style={{ width: `${item.received_qty/item.total_qty*100}%` }}
                      >&nbsp;</div>
                    </div>
                    <div className="z-10 relative text-slate-500 text-center text-sm mt-1">{item.received_qty}/{item.total_qty}</div>
                  </td>
                  <td className="p-3"><span className="uppercase">{item.status}</span></td>
                  <td className="p-3">{item.destination.warehouse_code}</td>
                  <td className="p-3">{item.ship_from_address?.name}</td>
                  <td className="p-3">
                    {item.shipment_type} 
                    {item.shipment_type === 'LTL' &&
                    <>
                      <IconDownload className="size-5 text-slate-500 ml-1 inline pb-1" role="button" data-tooltip-id="t-download" />
                      <Tooltip id="t-download" content="Download Bill of Ladding" />
                    </>
                    }
                  </td>
                  <td className="p-3">{item.created}</td>
                  <td className="p-3">{item.updated}</td>
                  <td className="p-3">
                    <div className="flex gap-3">
                    {item.tracking_data.length ?
                    <>
                      <IconMapLocation
                        className="size-5 text-slate-500"
                        data-tooltip-id="t-track"
                        role="button"
                        onClick={() => this.setState({ showTrackingShipmentId: item.shipment_id })}
                      />
                      <Tooltip id="t-track" content="View Tracking Numbers" />
                      {this.state.showTrackingShipmentId === item.shipment_id &&
                      <ViewTrackingNumbersModal
                        open={true}
                        onClose={() => this.setState({  showTrackingShipmentId: null })}
                        carriers={item.tracking_data}
                      />
                      }
                    </>
                    :
                    <>
                      <IconInput
                        className="size-5 text-slate-500"
                        role="button"
                        data-tooltip-id="t-input-tracking"
                        onClick={() => this.setState({ showAddTrackingShipmentId: item.shipment_id })}
                      />
                      <Tooltip id="t-input-tracking" content="Add Tracking Numbers" />
                      {this.state.showAddTrackingShipmentId === item.shipment_id &&
                      <AddTrackingNumbersModal
                        open={true}
                        onClose={() => this.setState({ showAddTrackingShipmentId: null })}
                        packages={item.shipment_type === 'SPD' ? item.boxes : item.pallets}
                      />
                      }
                    </>
                    }
                    <Button
                      className="btn-tertiary btn-sm text-red-500"
                      onClick={() => this.setState({ cancelId: item.shipment_id })}
                    >Cancel Inbound Plan</Button>
                    {this.state.cancelId === item.shipment_id &&
                    <ConfirmDialog
                      open={true}
                      onClose={() => this.setState({ cancelId: false })}
                      type="danger"
                      title="WARNING!"
                      text="Are you sure want to cancel?"
                      confirmBtnText="Yes"
                      onConfirm={() => {}}
                    />
                    }
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </>
          : <p className="text-slate-500">Shipments sent to Amazon and marked 'shipped' will be shown here.</p>
          }
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              data={{ page: 1, pages: 2 }}
              //callback={}
            />
          </div>
        </>
        }
      </div>
    )
  }
}

export default Shipped