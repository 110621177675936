import React, { Component } from 'react'
import Modal from "./Modal"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Input } from "@headlessui/react"
import { ReactComponent as IconPrint } from '../assets/icons/icon-print.svg'
import { productNoImage } from "../assets/images"

class PrintItemLabelsModal extends Component {
  state = {
    labels_amount: ''
  }

  render() {
    const { open, onClose, item_data, onExpirationDateChange, onPrintDone } = this.props

    return (
      <Modal open={open} onClose={onClose} size="xl">
        <div className="flex gap-4">
          <div className="basis-1/2 text-center border rounded">
            <div
              className={`p-2 mx-auto min-h-[500px] relative bg-no-repeat bg-center`}
              style={{ backgroundImage: `url('${productNoImage}')`}}
            >
              <img
                // we need large image dimension here, recommended 500px in width
                // but fetching image by ASIN has no guarantee it will return the image, so ideally it's needed on db
                src={`https://images.amazon.com/images/P/${item_data.asin1}.01._SCLZZZZZZZ_.jpg`}
                alt=""
                className="relative z-20"
              />
            </div>
          </div>
          <div className="basis-1/2">
            <h6 className="font-semibold mb-6">{item_data.item_name}</h6>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <span className="text-slate-500">SKU</span>
                <span className="block text-lg font-semibold">{item_data.seller_sku}</span>
              </div>
              <div>
                <span className="text-slate-500">FNSKU</span>
                <img src="https://i.ibb.co.com/C6y77g6/Screen-Shot-2024-08-11-at-16-04-29.png" alt="" />
              </div>
            </div>
            <div className="mt-4">
              <span className="text-slate-500">Notes</span>
              <p className="text-orange-600"><em>{item_data.notes}</em></p>
            </div>
            <div className="mt-4">
              <span className="text-slate-500">Amazon Prep Instruction</span>
              <p>-</p>
            </div>
            <div className="mt-4">
              <span className="text-slate-500 block mb-1">Expiration Date <small>(only if applicable)</small></span>
              <DatePicker
                selected={item_data.expiration_date ? item_data.expiration_date : null}
                onChange={selected => onExpirationDateChange(selected)}
              />
            </div>
            {/* show quantity remaining if user working on active shipment, 
            later this modal can be reused on other places like my inventory, but qty remaining isn't relevant there */}
            {(item_data.qty !== undefined && item_data.done_qty !== undefined) ?
            <div className="mt-4">
              <span className="text-slate-500">Qty Remaining</span>
              <span className="block py-2">{item_data.qty - item_data.done_qty}</span>
            </div>
            : null
            }
            <div className="mt-4">
              <span className="text-slate-500">Item labels to print</span>
              <Input
                type="number"
                min={1}
                className="block"
                value={this.state.labels_amount}
                onChange={e => this.setState({ labels_amount: parseInt(e.target.value) })}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 mt-4">
          <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
          <Button
            className="btn-primary"
            disabled={!this.state.labels_amount}
            onClick={() => {
              onPrintDone(this.state.labels_amount)
              onClose()
            }}
          >
            <IconPrint className="size-4 inline mr-2" />
            <span>Print Item Labels</span>
          </Button>
        </div>
      </Modal>
    )
  }
}

export default PrintItemLabelsModal