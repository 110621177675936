import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, CloseButton, Popover, PopoverButton, PopoverPanel } from "@headlessui/react"

class AddressPopover extends Component {

  render() {
    const {
      buttonText,
      anchor,
      select,
      selectedAddress,
      addNewAddress,
      addresses,
    } = this.props

    return (
      <Popover className="relative">
        <PopoverButton as="span" role="button" className="text-green-700">{buttonText ? buttonText : 'Open' }</PopoverButton>
        <PopoverPanel anchor={anchor ? anchor : 'bottom start'} className="p-3 rounded shadow-lg bg-white border">
          <CloseButton as="div">
            <Button className="btn-secondary btn-sm mb-2 w-full" onClick={addNewAddress}>Add new address</Button>
            <div className="max-h-72 flex flex-col gap-2">
              {addresses?.map(item => (
                <div
                  key={`address-${item.id}`}
                  className="p-2 border rounded text-sm hover:border-slate-400 data-[selected]:bg-slate-50 data-[selected]:border-slate-300"
                  data-selected={selectedAddress?.id === item.id ? true : null}
                  role="button"
                  onClick={() => select(item)}
                >
                  <h6 className="uppercase">
                    {item.name} {item.is_default ? <span className="bg-slate-100 p-1 rounded text-xs font-semibold">DEFAULT</span> : null}
                  </h6>
                  {item.address_line_1} {item.address_line_2}
                  <br/>
                  {item.city}, {item.state_or_province_code}, {item.postal_code}, {item.country_code}
                </div>
              ))}
            </div>
          </CloseButton>
        </PopoverPanel>
      </Popover>
    )
  }
}

export default connect(
  state => ({
    addresses: state.User.get("addresses"),
    user_address_working: state.User.get("user_address_working"),
  }),
  {
  }
)(AddressPopover);
