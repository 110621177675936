import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";

export default function PopoverOnHover({ showPopover, buttonText, children, onMouseEnter, onMouseLeave }) {
  return (
    <Popover className="relative">
    <PopoverButton
      as="span"
      className="underline cursor-pointer hover:no-underline"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >{buttonText}</PopoverButton>
    <Transition
      show={showPopover}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <PopoverPanel anchor="bottom start" className="px-3 py-2 bg-white shadow-lg rounded border text-center">
        {children}
      </PopoverPanel>
    </Transition>
  </Popover>
  )
}